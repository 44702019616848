<!-- Stepper.vue -->
<template>
  <div style="display:flex; justify-content: space-between; gap:0.5rem">
    <div>
      <label class="ml-0">Basic Info History</label>
      <b-card class="pl-1 pr-1">
        <div v-for="(step, index) in steps" :key="index">
          <div class="stepper mt-1">
            <div>
              <div style="display: flex; justify-content: space-evenly;">
                <div
                  v-if="step.status == 'SENT' || step.status == 'APPROVED' || step.status == 'REMINDER_SENT' ||  step.status == 'FAILED'"
                  style="display:flex; justify-content: space-between; align-items: center;"
                  :class="{ 'step-active': isActiveStep(step.status) }"
                >
                <b-icon 
                   v-if="step.status == 'SENT'"
                    class="upDownIcon"
                    icon="check-circle-fill"
                    color="orange"
                    font-scale="2.2"
                  />
                  <b-icon 
                   v-if="step.status == 'APPROVED'"
                    class="upDownIcon"
                    icon="check-circle-fill"
                    color="#65b741"
                    font-scale="2.2"
                  />
                  <b-icon 
                   v-if="step.status == 'REMINDER_SENT'"
                    class="upDownIcon"
                    icon="check-circle-fill"
                    color="#5e78f3"
                    font-scale="2.2"
                  />
                  <b-icon 
                   v-if="step.status == 'FAILED'"
                    class="upDownIcon"
                    icon="question-circle-fill"
                    color="red"
                    font-scale="2.2"
                  />
                  <span style="display:inline-block; margin-top:5px;"></span>
                  <!-- <span
                  style="width: 250px; height: 4px; background-color:#65B741 ;"
                ></span> -->
                  <br />
                </div>
                <div
                  v-else
                  style="display:flex; justify-content: space-between; align-items: center;"
                >
                  <b-icon
                    class="upDownIcon"
                    color="red"
                    icon="x-circle-fill"
                    font-scale="2.2"
                  />
                  <span
                    style="display:inline-block; margin-top:5px; margin-left: 5px;"
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <div
            style="display: flex; justify-content: space-between; padding-left: 5px;"
          >
            <div>
              <span>
                <strong><span v-if="step.status == 'REMINDER_SENT' "> REMINDER SENT</span> <span v-else>
                  {{ step.status }} <span v-if="step.status == 'FAILED' ">- {{ step.ErrorCode }}</span> <feather-icon v-if="step.status == 'FAILED' "
                   icon="InfoIcon" v-b-tooltip.hover.x="step.ErrorMessage"></feather-icon>
                </span></strong>
              </span>
              <br />
              <span style="font-size: 10px;">
                {{
                  new Date(step.timestamp).toLocaleString("en-US", {
                    timeZone: "Asia/Kolkata",
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                  })
                }}</span
              >
            </div>
                    <!-- <div v-if="steps[index + 1]">
              <span>
                <strong>{{ steps[index + 1].status }}</strong>
              </span>
              <br />
              <span style="font-size: 10px;">{{ steps[index + 1].timestamp }}</span> -->
                    <!-- </div> -->
          </div>
        </div>
      </b-card>
    </div>
    <div>
      <label class="ml-0">Creatives History</label>
      <b-card class="pl-1 pr-1">
        <div v-if="stepsBasic.length == 0">
          <p>History not found.</p>
        </div>
        <div v-else>
          <div v-for="(step, index) in stepsBasic" :key="index">
          <div class="stepper mt-1">
            <div>
              <div style="display: flex; justify-content: space-evenly;">
                <div
                  v-if="step.status == 'SENT' || step.status == 'APPROVED' || step.status == 'FAILED' ||  step.status == 'REMINDER_SENT'"
                  style="display:flex; justify-content: space-between; align-items: center;"
                  :class="{ 'step-active': isActiveStep(step.status) }"
                >
                <b-icon 
                v-if="step.status == 'SENT'"
                    class="upDownIcon"
                    icon="check-circle-fill"
                    color="orange"
                    font-scale="2.2"
                  />
                  <b-icon 
                   v-if="step.status == 'REMINDER_SENT'"
                    class="upDownIcon"
                    icon="check-circle-fill"
                    color="#5e78f3"
                    font-scale="2.2"
                  />
                  <b-icon 
                   v-if="step.status == 'APPROVED'"
                    class="upDownIcon"
                    icon="check-circle-fill"
                    color="#65b741"
                    font-scale="2.2"
                  />
                  <b-icon 
                   v-if="step.status == 'FAILED'"
                    class="upDownIcon"
                    icon="question-circle-fill"
                    color="red"
                    font-scale="2.2"
                  />
                  <span style="display:inline-block; margin-top:5px;"></span>
                  <!-- <span
                  style="width: 250px; height: 4px; background-color:#65B741 ;"
                ></span> -->
                  <br />
                </div>
                <div
                v-if="step.status == 'DENIED'"
                  style="display:flex; justify-content: space-between; align-items: center;"
                >
                  <b-icon
                    class="upDownIcon"
                    color="red"
                    icon="x-circle-fill"
                    font-scale="2.2"
                  />
                  <span
                    style="display:inline-block; margin-top:5px; margin-left: 5px;"
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <div
            style="display: flex; justify-content: space-between; padding-left: 5px;"
          >
            <div>
              <span>
                <strong><span v-if="step.status == 'REMINDER_SENT' "> REMINDER SENT</span> <span v-else>
                  {{ step.status }} <span v-if="step.status == 'FAILED' ">- {{ step.ErrorCode }}</span> <feather-icon v-if="step.status == 'FAILED' "
                   icon="InfoIcon" v-b-tooltip.hover.x="step.ErrorMessage"></feather-icon>
                </span></strong>
              </span>
              <br />
              <span style="font-size: 10px;">
                {{
                  new Date(step.timestamp).toLocaleString("en-US", {
                    timeZone: "Asia/Kolkata",
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                  })
                }}</span
              >
            </div>
          </div>
        </div>
        </div>
        
      </b-card>
    </div>
  </div>
</template>

<script>
import { BIcon, BModal, BCard } from "bootstrap-vue";

export default {
  data() {
    return {
      modalVisible: false,
    };
  },
  components: {
    BIcon,
    BModal,
    BCard,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    stepsBasic: {
      type: Array,
      required: true,
    },
    activeSteps: {
      type: Array,
      required: true,
    },
  },
  methods: {
    isActiveStep(index) {
      return index === "SENT" || index === "APPROVED";
    },
  },
};
</script>

<style scoped>
.stepper {
  display: flex;
  justify-content: space-between;
}

.step-active {
  color: #65b741;
}

.line {
  flex: 1;
  height: 2px;
  background-color: #000;
  margin: 0 5px;
}
</style>
