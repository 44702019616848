<template>
  <div>
    <b-overlay :show="isLoadClientListForGMBDealers || isSummaryPageLoad || newSummaryPageLoad
      " class="dashboard-overlay" rounded="sm">
      <b-card class="tabs-container screenAlignment">
        <b-tabs fill>
          <b-tab title="Summary" active @click="changeTab('Summary_new')"></b-tab>
          <b-tab title="Dealers" @click="changeTab('Dealers')"> </b-tab>
          <b-tab title="Creatives" @click="changeTab('Creatives')"> </b-tab>
          <b-tab title="Dealer Responses" @click="changeTab('Incoming')"> </b-tab>
        </b-tabs>
      </b-card>
      <div v-if="activeTab === 'Summary_new'">
        <div class="headerManagerContainer" style="margin-left: -8px;margin-right: -8px; border-radius: 5px;">
          <div class="cardContainerForSubHeader">
            <div style="font-size: large; color: #000000; font-weight: bold; font-size: 20px; margin-top:8px">
              <span style="display:flex; justify-content: center; gap:2px">
                <b-icon class="upDownIcon" icon="house-door" font-scale="1.2" color="red" style="margin-right:5px ;" />
                Summary
              </span>
            </div>
          </div>
          <div class="summary-filters" style="margin-top:5px">
            <div class="creativesSubHeaderSummary">
              <div style="margin-top: 16px;">Clients:</div>
              <div class="SelectBoxContainer">
                <div class="form-group form-container1" style="width:200px;">
                  <div class="select-container1">
                    <select style="margin-top: 8px;" v-model="selectedSummary" class="gmb-client-select"
                      @change="getSummaryNewData">
                      <option value="">Select Client</option>
                      <option v-for="onetype in fetchClientsList" class="form-control" id="lg_type" name="lg_type"
                        :value="[onetype._id, onetype.name]">
                        {{ onetype.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div>
              </div>
            </div>
            <div class="creativesSubHeaderSummary" style="margin-top:-14px">
              <GlobalDateRangeSelector @fetchNewData="getSummaryNewData" />
            </div>
            <!-- <div class="creativesSubHeaderSummary">
              <div style="margin-top: 15px;">Months:</div>
              <div class="SelectBoxContainer">
                <div class="form-group form-container1" style="width:150px;">
                  <div class="select-container1">
                    <select v-model="selectedDelearsData" class="gmb-client-select" style="margin-top:8px"
                      @change="getAllSummaryChartData">
                      <option value="">Select Month</option>
                      <option v-for="onetype in dealersMonthSelections" :value="onetype.value" class="form-control"
                        :disabled="isMonthSummaryDisabled(onetype)" id="lg_type" name="lg_type"
                        v-bind:key="onetype.value">
                        {{ onetype.label }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- <div class="creativesSubHeaderSummary">
              <div style="margin-top: 15px; margin-left: 5px;">Year:</div>
              <div class="SelectBoxContainer">
                <div class="form-group form-container1" style="width:150px;">
                  <div class="select-container1">
                    <b-form-select style="margin-top:8px" id="yearSelect" v-model="selectedYear"
                      @change="getAllSummaryTabelData">
                      <option v-for="year in years" :value="year.label" :key="year.label">
                        {{ year.label }}
                      </option>
                    </b-form-select>
                  </div>
                </div>
              </div>
            </div> -->

          </div>

        </div>
        <b-row class="mt-1"></b-row>
        <!-- 
        <b-row class="graph-summary-dealers card-summary">
          <b-col cols="3" class="graph-card-summary card-container-graph">
            <b-card class="card-tiny-line-stats" body-class="pb-50" style="height:auto">
              <h6 class="text-xs text-center chart-text">Paid SV</h6>
              <h2 class="font-weight-bolder text-center app-title lg" v-if="this.storeVisitsCount > 0">
                {{ this.storeVisitsCount }}
              </h2>
              <h2 v-else class="font-weight-bolder text-center app-title lg">
                0
              </h2>
              <div class="chartSummaryContainer">
                <apexchart type="bar" :options="optionsSummaryNew" :series="seriesSummaryNewCPI"></apexchart>
              </div>
              <div style="text-align: center;">
                <h6 class="text-center mt-2" style=" font-size: 11px !important; margin-top: -15px;">
                  <span>
                  {{Math.abs(parseInt(this.storeVisitsDeltaPrevMonth) ) }} %
                  </span>

                  <feather-icon v-if="parseInt(this.storeVisitsDeltaPrevMonth) < 0" size="15" icon="ChevronsDownIcon"
                    :color="this.storeVisitsColor" style="margin-bottom: 3px;" />
                  <feather-icon v-if="parseInt(this.storeVisitsDeltaPrevMonth) > 0" size="15" icon="ChevronsUpIcon"
                    :color="this.storeVisitsColor" style="margin-bottom: 3px;" />
                  <feather-icon size="15" icon="InfoIcon" color="grey"
                    style="margin-bottom: 3px; cursor: pointer; margin-left: 3px" v-b-tooltip.hover.x="tooltipText" />
                </h6>
              </div>
            </b-card>
          </b-col>
          <b-col cols="3" class="graph-card-summary">
            <b-card class="card-tiny-line-stats" body-class="pb-50" style="height:auto">
              <h6 class="text-xs text-center chart-text">Incremental SV</h6>
              <h2 class="font-weight-bolder text-center app-title lg">
                {{
                  this.incrementalStoreVisitsCount == null
                  ? 0
                  : this.incrementalStoreVisitsCount
                }}
              </h2>
              <div style="justify-content: center; align-items: center;  margin-left: auto; margin-right: auto; ">
                <apexchart type="bar" :options="optionsSummaryNew" :series="seriesSummaryNewCPICPISV"></apexchart>
              </div>
              <div style="text-align: center;">
                <h6 class="text-center mt-2" style=" font-size: 11px !important; margin-top: -15px;">
                  {{
                    Math.abs(
                      parseFloat(this.incrementalStoreVisitsDeltaPrevMonth)
                    )
                  }}
                  %
                  <feather-icon v-if="parseInt(this.incrementalStoreVisitsDeltaPrevMonth) < 0
                      " size="15" icon="ChevronsDownIcon" :color="this.incrementalStoreVisitsColor"
                    style="margin-bottom: 3px;" />
                  <feather-icon v-if="parseInt(this.incrementalStoreVisitsDeltaPrevMonth) > 0
                    " size="15" icon="ChevronsUpIcon" :color="this.incrementalStoreVisitsColor"
                    style="margin-bottom: 3px;" />
                  <feather-icon size="15" icon="InfoIcon" color="grey"
                    style="margin-bottom: 3px; cursor: pointer; margin-left: 3px" v-b-tooltip.hover.x="tooltipText" />
                </h6>
              </div>
            </b-card>
          </b-col>

          <b-col cols="3" class="graph-card-summary">
            <b-card class="card-tiny-line-stats " body-class="pb-50" style="height:auto">
              <h6 class="text-xs  text-center chart-text">CPSV</h6>
              <h2 class="font-weight-bolder text-center  app-title lg">
                {{ this.costPerStoreVisitCountFormatted }}
              </h2>
              <div style="justify-content: center; align-items: center; margin-left: auto; margin-right: auto;">
                <apexchart type="bar" ref="redar" :options="optionsSummaryNew" :series="seriesSummaryNewCPSV">
                </apexchart>
              </div>
              <div style="text-align: center;">
                <h6 class="text-center mt-2" style=" font-size: 11px !important; margin-top: -15px;">
                  {{
                    Math.abs(parseFloat(this.costPerStoreVisitDeltaPrevMonth))
                  }}
                  %
                  <span v-if="parseInt(this.costPerStoreVisitDeltaPrevMonth) < 0">
                    <feather-icon size="15" icon="ChevronsDownIcon" :color="this.costPerStoreVisitColor"
                      style="margin-bottom: 3px;" />
                  </span>
                  <span v-if="parseInt(this.costPerStoreVisitDeltaPrevMonth) > 0">
                    <feather-icon size="15" icon="ChevronsDownIcon" :color="this.costPerStoreVisitColor"
                      style="margin-bottom: 3px;" />
                  </span>
                  <feather-icon size="15" icon="InfoIcon" color="grey"
                    style="margin-bottom: 3px; cursor: pointer; margin-left: 3px" v-b-tooltip.hover.x="tooltipText" />
                </h6>
              </div>
            </b-card>
          </b-col>
          <b-col cols="3" class="graph-card-summary">
            <b-card class="card-tiny-line-stats" body-class="pb-50" style="height:auto">
              <h6 class="text-xs text-center chart-text">
                CPISV
              </h6>
              <h2 class="font-weight-bolder text-center app-title lg">
                {{ this.costPerIncrementalStoreVisitCountFormatted }}
              </h2>
              <div style="justify-content: center; align-items: center; margin-left: auto; margin-right: auto;">
                <apexchart type="bar" :options="optionsSummaryNew" :series="seriesSummaryNewCPIIncremental"></apexchart>
              </div>
              <div style="text-align: center;">
                <h6 class="text-center mt-2" style=" font-size: 11px !important; margin-top: -15px;">
                  {{
                    Math.abs(
                      parseFloat(
                        this.costPerIncrementalStoreVisitDeltaPrevMonth
                      )
                    )
                  }}
                  %
                  <feather-icon v-if="parseInt(
                        this.costPerIncrementalStoreVisitDeltaPrevMonth
                      ) < 0
                      " size="15" icon="ChevronsDownIcon" :color="this.incrementalStoreVisitsColor"
                    style="margin-bottom: 3px;" />
                  <feather-icon v-if="parseInt(
                    this.costPerIncrementalStoreVisitDeltaPrevMonth
                  ) > 0
                    " size="15" icon="ChevronsUpIcon" :color="this.costPerIncrementalStoreVisitColor"
                    style="margin-bottom: 3px;" />
                  <feather-icon size="15" icon="InfoIcon" color="grey"
                    style="margin-bottom: 3px; cursor: pointer; margin-left: 3px" v-b-tooltip.hover.x="tooltipText" />
                </h6>
              </div>
            </b-card>
          </b-col>
        </b-row> -->
        <div class="summaryContainer">
          <b-row>
            <b-col cols="12">
              <b-card>
                <b-card-title>
                  <h3 class="app-title mdsm text-left chart-text">
                    Summary Dashboard
                  </h3>
                </b-card-title>
                <MultiColorTable :PaidData="summaryNewData" TableType="SummaryNewBrandTable"
                  :SummaryTotalData="SummaryNewTotalData" />
              </b-card>
            </b-col>
          </b-row>
          <!-- <b-row>
            <b-col cols="12">
              <b-card>
                <b-card-title>
                  <h3 class="app-title mdsm text-left chart-text">
                    Keyword Level Performance
                  </h3>
                </b-card-title>
                <MultiColorTable :PaidData="keywordPlannerData" TableType="keywordPlanner" 
                 />
              </b-card>
            </b-col>
          </b-row> -->
        </div>
      </div>
      <div v-if="activeTab === 'Dealers'">
        <b-overlay :show="isGetDealersData" rounded="sm" class="dashboard-overlay-individual">
          <b-card style="height: auto; justify-content: center; margin-left:-5px; margin-right:-5px">
            <div class="headerManagerContainer">
              <div class="cardContainerForSubHeader">
                <div style="font-size: large; color: #000000; font-weight: bold; font-size: 20px;">
                  <span style="display:flex; justify-content: center; gap:2px;margin-top:2px ;"> <b-icon
                      class="upDownIcon" icon="shop" font-scale="1.2" color="red"
                      style="margin-right:10px ;" />Dealers</span>
                </div>
              </div>
              <div class="summary-filters" style="margin-top:5px">
                <div class="creativesSubHeader">
                  <div class="creativesSubHeaderSummary">
                    <div style="margin-top: 10px;">Clients:</div>
                    <div class="SelectBoxContainer">
                      <div class="form-group form-container1" style="width:200px;">
                        <div class="select-container1">
                          <select v-model="selectedSummary" class="gmb-client-select" @change="getDeadersDataOnchange">
                            <option value="">Select Client</option>
                            <option v-for="onetype in fetchClientsList" :value="[onetype._id, onetype.name]"
                              class="form-control" id="lg_type" name="lg_type" v-bind:key="onetype._id">
                              {{ onetype.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="creativesSubHeaderSummary">
                    <b-form-input v-model="searchQueryIntoDealers" id="client-name"
                      placeholder="Dealer name, address, owner field" style="max-width: 100%;" type="text" />
                  </div>
                  <div class="creativesSubHeaderSummary" style="margin-top: -2%;">
                    <b-button variant="link" @click="isUploadDealerModal()">
                      <span style="display:flex; justify-content: center; gap:2px"><feather-icon icon="UploadIcon"
                          size="15" /> Upload</span>

                    </b-button>

                    <b-button variant="link" @click="exportTableToCSV" style="margin-left: -15px;">
                      <span style="display:flex; justify-content: center; gap:2px"><feather-icon icon="DownloadIcon"
                          size="15" /> Download </span>

                    </b-button>

                    <b-button @click="refreshPage" variant="link" v-b-tooltip.hover.top="'Refresh'">
                      <feather-icon icon="RefreshCwIcon" size="14" />
                    </b-button>

                  </div>
                </div>
              </div>
            </div>
            <div v-if="this.getDealersDataItem.length == 0"
              style="text-align: center; justify-content: center; align-items:  center; margin-left: 0%; right:auto ; margin-top:10% ; height: 20vh;">
              No Dealer found for selected client.
            </div>
            <div v-else class="manage-overflow">
              <table class="my-table">
                <thead>
                  <tr style="font-size: 13px;">
                    <th>Dealer's Name</th>
                    <th style="border: 1px solid #000000;">Owner Name</th>
                    <th style="border: 1px solid #000000;">Address</th>
                    <th style="border: 1px solid #000000;" class="misCode">
                      <span>
                        Type - MIS Code
                      </span> <br />
                      Mobile Number
                    </th>
                    <th style="border: 1px solid #000000;"><span>
                        Budget
                      </span><br /><span>(Monthly)</span></th>
                    <th style="border: 1px solid #000000;"><span>
                        Campaign
                      </span><br /><span>Duration</span></th>
                    <th style="border: 1px solid #000000;">
                      Basic Info Confirmation
                    </th>
                    <th style="border: 1px solid #000000;">Creatives Approval</th>
                    <th style="border: 1px solid #000000;">Track</th>
                    <th>Weekly Reports</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in this.paginatedDealers" :key="index" style="font-size:12px">
                    <td style="text-align: center;">
                      <div style="display: flex; align-items: center; margin-top: 5px; text-align: center;">
                        <div style="display: flex; gap: 1rem; align-items: center; text-align: center; margin-top: 10px;">
                          <b-icon v-if="item.instagramLink !== ''" @click="redirectToUrl(item.instagramLink)"
                            icon="instagram" color="#200E6B" font-scale="1.4" style="cursor: pointer;"></b-icon>
                          <div style="cursor: pointer; color: blue; text-decoration: underline;"
                            @click="getIndividualDealerData(item.dealerID, item.dealerName, item.dealerMisCode)">
                            {{ item.dealerName }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>{{ item.ownerName }} </td>
                    <td>{{ item.completeBusinessAddress }} <b-icon v-if="item.googleLink !== ''"
                        @click="redirectToUrl(item.googleLink)" icon="geo-alt-fill" color="#200E6B" font-scale="1.2"
                        style="cursor: pointer;"></b-icon></td>
                    <td> <span>
                        {{ item.dealerType }} - {{ item.dealerMisCode }}
                      </span> <br />{{ item.mobileNo }}</td>

                    <td v-if="item.budget && parseInt(item.budget.amount, 10) > 0">
                      <div style="display: flex; justify-content: space-around; align-items: center;">
                        <span v-if="parseInt(item.budget.amount, 10) > 0" style="margin-left:15px"><span> {{
                          item.budget.currencyCode == 'INR' ? '₹' : '$' }}</span> </span>
                        <span style="margin-left:2px"> {{ item.budget.amount == null ? '' :
                          formatMoney(item.budget.amount) }}</span>
                        <b-button v-if="parseInt(item.budget.amount, 10) > 0" variant="link" style="margin-left: -15px;"
                          @click="isOpenAlloctedBudgetDealersModalDealers(item.dealerID, item.dealerName, item.mobileNo, item.budget.amount)">
                          <feather-icon size="15" icon="Edit2Icon" />
                        </b-button>
                      </div>
                    </td>
                    <td v-else>
                      <b-button @click="
                        isOpenAlloctedBudgetDealersModalDealers(
                          item.dealerID,
                          item.dealerName,
                          item.mobileNo,
                          item.budget
                        )
                        " variant="primary" small style="font-size:9px">Allocate</b-button>
                    </td>
                    <td>
                      <div v-if="item.campaignDuration && Object.keys(item.campaignDuration).length > 0">
                        <span style="font-size: 12px; height: 30px;">
                          {{ formatDate(item.campaignDuration.startDate) }}<br />
                          -<br />
                          {{ formatDate(item.campaignDuration.endDate) }}
                        </span>
                      </div>
                      <div v-else>
                        <b-button variant="link" small @click="getCampaignsData(item)">
                          <span>
                            <b-icon icon="clock-fill" font-scale="1.8"
                              style="box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); border-radius: 20px;" />
                          </span>
                        </b-button>
                      </div>

                    </td>
                    <td>
                      <div style="display:flex; gap:5px; justify-content: center;">
                        <b-button v-if="item.latestBasicInfoApprovalState == 'NOT_SENT'" small variant="primary"
                          @click="getBasicInfo(item)" style="font-size:9px; width:85px">Confirm</b-button>
                        <p v-if="item.latestBasicInfoApprovalState == 'APPROVED'" v-b-tooltip.hover
                          title="Basic Info has been approved.">
                          <span
                            style="width: 90px; background-color: #e4f6d6;
                          ; color: #6fd227; border-radius: 10px; padding-left: 12px;  padding-right: 12px; padding-top: 10px;  padding-bottom: 10px; margin-left:0px; font-weight: bold; cursor: pointer; ">
                            Approved
                          </span>
                        <div @click="openPreviewBasicInfo(item, item.latestBasicInfoApprovalState)">
                          <feather-icon class="previewInfo" v-if="item.latestBasicInfoApprovalState == 'APPROVED'"
                            icon="InfoIcon" />
                        </div>
                        </p>
                        <span v-if="item.latestBasicInfoApprovalState == 'SENT'"
                          style="display:flex; justify-content: space-around" v-b-tooltip.hover
                          title="Basic Info has been sent.">
                          <span
                            style="width: 90px; background-color: #fff3d6; color: #fcb915; border-radius: 10px; padding-left: 25px;  padding-right: 25px; padding-top: 8px;  padding-bottom: 8px; font-weight: bold; cursor: pointer;margin-left:-15px">
                            Sent
                          </span>
                          <span @click="openPreviewBasicInfo(item, item.latestBasicInfoApprovalState)">
                            <feather-icon class="previewInfo" v-if="item.latestBasicInfoApprovalState == 'SENT'"
                              style="margin-left:5px; margin-top:10px; cursor:pointer" icon="InfoIcon" />
                          </span>
                        </span>
                        <span v-if="item.latestBasicInfoApprovalState == 'REMINDER_SENT'"
                          style="display:flex; justify-content: space-around" v-b-tooltip.hover
                          title="Basic Info has been sent.">
                          <span
                            style="width: 90px; background-color: rgb(189 206 245);color: rgb(77 102 255); border-radius: 10px; padding-left: 10px;  padding-right: 10px; padding-top: 8px;  padding-bottom: 8px; font-weight: bold; cursor: pointer;margin-left:-15px">
                            Reminded
                          </span>
                          <span @click="openPreviewBasicInfo(item, item.latestBasicInfoApprovalState)">
                            <feather-icon class="previewInfo" v-if="item.latestBasicInfoApprovalState == 'REMINDER_SENT'"
                              style="margin-left:5px; margin-top:10px; cursor:pointer" icon="InfoIcon" />
                          </span>
                        </span>
                        <span v-if="item.latestBasicInfoApprovalState == 'DENIED'"
                          style="width: 82px; background-color: #ffe2e3; color: #fc5a60; border-radius: 10px; padding-left: 7px;  padding-right: 7px; padding-top: 8px;  padding-bottom: 8px; font-weight: bold; margin-left:-15px"
                          v-b-tooltip.hover title="Basic Info has not been rejected.">
                          Denied
                        </span>
                        <span @click="openPreviewBasicInfo(item, item.latestBasicInfoApprovalState)">
                          <feather-icon class="previewInfo" v-if="item.latestBasicInfoApprovalState == 'DENIED'"
                            style="margin-top:10px;" icon="InfoIcon" />
                        </span>
                        <span v-if="item.latestBasicInfoApprovalState == 'FAILED'"
                          style="width: 92px; background-color: #ffe2e3; color: #fc5a60; border-radius: 10px; padding-left: 7px;  padding-right: 7px; padding-top: 8px;  padding-bottom: 8px; font-weight: bold; margin-left:-28px"
                          v-b-tooltip.hover title="Failed to send basic info via WhatsApp.">
                          Failed
                        </span>
                        <span @click="openPreviewBasicInfo(item, item.latestBasicInfoApprovalState)">
                          <feather-icon class="previewInfo" v-if="item.latestBasicInfoApprovalState == 'FAILED'"
                            style="margin-top:10px;" icon="InfoIcon" />
                        </span>
                      </div>
                    </td>
                    <td>
                      <div v-if="item.creativesUploaded == false">
                        upload creative
                      </div>
                      <span v-else-if="item.latestCreativesApprovalState == 'REMINDER_SENT'"
                        style="display:flex; justify-content: space-around" v-b-tooltip.hover
                        title="Approval Creatives Reminder has been sent.">
                        <span
                          style="width: 90px; background-color: rgb(189 206 245);color: rgb(77 102 255); border-radius: 10px; padding-left: 10px;  padding-right: 10px; padding-top: 8px;  padding-bottom: 8px; font-weight: bold; cursor: pointer;margin-left:0px">
                          Reminded
                        </span>
                        <span @click="previewCreatives(item.approvalCreative, item.dealerName,
                          item.mobileNo)">
                          <feather-icon class="previewInfo" v-if="item.latestCreativesApprovalState == 'REMINDER_SENT'"
                            style="margin-left:0px; margin-top:10px; cursor:pointer" icon="InfoIcon" />
                        </span>
                      </span>
                      <span style="display:flex; justify-content: space-around;"
                        v-else-if="item.latestCreativesApprovalState == 'FAILED'" v-b-tooltip.hover
                        title="Failed to send creatives via WhatsApp.">
                        <span
                          style="width: 90px; background-color: #ffe2e3; color: #fc5a60; border-radius: 10px; padding-left: 20px;  padding-right: 20px; padding-top: 8px;  padding-bottom: 8px; font-weight: bold; cursor: pointer;margin-left:0px">
                          Failed
                        </span> <span @click="previewCreatives(item.approvalCreative, item.dealerName,
                          item.mobileNo)"><feather-icon icon="InfoIcon"
                            style="margin-top:10px ; cursor:pointer" /></span>
                      </span>
                      <span style="display:flex; justify-content: space-around;"
                        v-else-if="item.latestCreativesApprovalState == 'SENT'" v-b-tooltip.hover
                        title="Approval Creatives has been sent.">
                        <span
                          style="width: 90px; background-color: #fff3d6; color: #fcb915; border-radius: 10px; padding-left: 30px;  padding-right: 30px; padding-top: 8px;  padding-bottom: 8px; font-weight: bold; cursor: pointer;margin-left:0px">
                          Sent
                        </span> <span @click="previewCreatives(item.approvalCreative, item.dealerName,
                          item.mobileNo)"><feather-icon icon="InfoIcon"
                            style="margin-top:10px ;margin-left:2px ; cursor:pointer" /></span>
                      </span>
                      <div v-else-if="item.url == null">
                        <b-button @click="
                          isOpenApprovalDealersModalDealers(
                            item,
                            item.dealerID,
                            item.dealerName,
                            item.mobileNo,
                            item.approvalCreative,
                            item.ownerName
                          )
                          " variant="primary" style="font-size: 10px">
                          Get Approval
                        </b-button>
                      </div>
                      <div v-else>
                        <div style="position: relative; display: inline-block;">
                          <span style="position: absolute; top: -8px; right: -5px; cursor:pointer" v-b-tooltip.hover
                            title="Approval Creatives has been approved.">
                            <b-icon icon="check" font-scale="2.4" color='#65b741' />
                          </span>
                          <div @click="getPreviewImage(item.url)" style="cursor: pointer;">
                            <img :src="item.url" alt="Image Preview"
                              style="height: 50px; width: 110px; border: 1px solid black; border-radius: 5px;" />
                          </div>
                        </div>
                      </div>

                    </td>
                    <td>
                      <div style="cursor:pointer">
                        <b-button variant="link"
                          @click="openStatusModal(item.dealerName, item.basicInfoApprovalState, item.creativesApprovalState)">
                          <feather-icon icon="EyeIcon" size="15" />
                        </b-button>
                      </div>
                    </td>
                    <td>
                      <div style="align-items: center; justify-content: center; text-align: center; margin-left: 40%;">
                        <b-form-checkbox :id="'checkbox-' + index" v-model="item.isWeeklyReportEnabled"
                          :name="'checkbox-' + index" :checked="item.isWeeklyReportEnabled" :unchecked-value="false"
                          @change="handleCheckboxChange(item.isWeeklyReportEnabled, item.dealerID)">

                        </b-form-checkbox>
                      </div>

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-pagination v-if="Object.keys(this.getDealersDataItem).length > 0" v-model="currentDealerPage"
              :total-rows="totalDealers" :per-page="perPageDealers" @change="pageChanged" class="mt-2" first-number
              last-number align="right" prev-class="prev-item" next-class="next-item"></b-pagination>
          </b-card>
          <div>
            <b-row>
              <b-col cols="12">
                <b-card>
                  <!-- <div class="headerManagerContainer p-1">

                    <h3 class="app-title text-left" style="margin-top:5px">
                      {{ this.individualDealerName }}({{
                        this.individualDealerCode
                      }}): A 6-Month Progress Overview
                    </h3>
       
                  </div> -->
                  <div class="headerManagerContainer">
                    <div class="cardContainerForSubHeader">
                      <div style="font-size: large; color: #000000; font-weight: bold; font-size: 20px; width: 800px;">
                        <b-row>
                          <b-col cols="12">
                            <feather-icon icon="UserIcon" color="red" size="25" class="mr-1"
                              style="margin-top: 2px; margin-bottom: 6px;" /> {{
                                this.individualDealerName }}({{
    this.individualDealerCode
  }}): A 6-Month Progress Overview
                          </b-col>
                        </b-row>
                      </div>

                    </div>
                  </div>

                  <div v-if="isFetchedIndividualData"
                    style="text-align: center; justify-content: center; align-items:  center; margin-left: 0%; right:auto ; margin-top:0% ; padding: 100px;">
                    <b-spinner large> </b-spinner>
                  </div>
                  <div v-else>
                    <MultiColorTable :PaidData="individualData" TableType="Dealer" />
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </div>
          <div>
            <b-card style=" margin-left:-5px; margin-right:-5px">
              <div style="min-height: 40vh; max-height: auto justify-content: center;">
                <div>
                  <div class="headerManagerContainer">
                    <div class="cardContainerForSubHeader">
                      <div style="font-size: large; color: #000000; font-weight: bold; font-size: 20px;">
                        <feather-icon icon="UserXIcon" color="red" size="25" class="mr-1"
                          style="margin-top: 2px; margin-bottom: 5px;" />
                        Non Responding Dealers
                      </div>

                    </div>
                  </div>

                  <div v-if="this.paginatedNonRespondingDealersLists.length == 0"
                    style="text-align: center; justify-content: center; align-items:  center; margin-left: 0%; right:auto ; margin-top:10% ; height: 20vh;">
                    No data for non-responding dealers.
                  </div>
                  <div v-else>
                    <table class="my-table">
                      <thead>
                        <tr style="font-size: 13px;">
                          <th>Dealer's Name</th>
                          <th style="border: 1px solid #000000;">Owner Name</th>
                          <th style="border: 1px solid #000000;">
                            Mobile No
                          </th>
                          <th style="border: 1px solid #000000;">Basic Info</th>
                          <th>Creatives Approval</th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr style="font-size:12px" v-for="(item, index) in this.paginatedNonRespondingDealersLists">
                          <td>{{ item.dealerName }}</td>
                          <td>{{ item.ownerName }}</td>
                          <td>{{ item.mobileNo }}</td>
                          <td>
                            <span v-if="item && item.basicInfo">
                              <div style="display: flex; justify-content:space-around;">

                                <span v-if="item && item.basicInfo"> 📩 Sent on {{
                                  convertBackToOriginalFormat(item.basicInfo.lastMessageSentAt) }}
                                  <br />

                                  <span style="margin-left: -40px;">
                                    ({{
                                      item.basicInfo.hoursNoResponse }} hr(s) ago) <feather-icon icon="InfoIcon"
                                      @mouseover="showPreviewNonRespondingDealers(item, 'basic')"
                                      @mouseleave="previewOfNonResponfingFlag = false" />
                                  </span></span>

                                <span>
                                  <span v-if="item && item.basicInfo" variant="primary"
                                    @click="openRemindModal(item, 'basic info')" size="sm"
                                    style="font-size: 10px; margin-top: 5px; cursor: pointer; box-shadow: rgba(0, 0, 0, 0.5) 0px 6px 6px; padding: 6px; border-radius: 50%;">
                                    <b-icon icon="bell-fill" font-scale="1.5" color="#ec443c" />
                                  </span>
                                </span>
                              </div>
                            </span>
                            <span v-else>
                              Pending
                            </span>
                          </td>
                          <td>
                            <span v-if="item && item.creativesApproval">
                              <div style="display: flex; justify-content:space-around">


                                <span v-if="item && item.creativesApproval"> 📩 Sent on {{
                                  convertBackToOriginalFormat(item.creativesApproval.lastMessageSentAt) }} <br />
                                  <span style="margin-left: -40px;">
                                    ({{
                                      item.creativesApproval.hoursNoResponse }} hr(s) ago) <feather-icon icon="InfoIcon"
                                      @mouseover="showPreviewNonRespondingDealers(item, 'creatives')"
                                      @mouseleave="previewOfNonResponfingFlag = false" />
                                  </span>
                                </span>

                                <span>

                                  <span v-if="item && item.creativesApproval" variant="primary"
                                    @click="openRemindModal(item, 'creatives')" size="sm"
                                    style="font-size: 10px; margin-top: 5px; cursor: pointer; box-shadow: rgba(0, 0, 0, 0.5) 0px 6px 6px; padding: 6px; border-radius: 50%;">
                                    <b-icon icon="bell-fill" font-scale="1.5" color="#ec443c" />
                                  </span>
                                </span>

                              </div>
                            </span>
                            <span v-else>
                              Pending
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <b-pagination v-if="this.paginatedNonRespondingDealersLists.length > 0"
                      v-model="currentUnRespondingPage" :total-rows="nonRespondingDealersList.length"
                      :per-page="itemsunRespondingPerPage" class="mt-1" first-number last-number align="right"
                      prev-class="prev-item" next-class="next-item"></b-pagination>
                  </div>

                </div>
              </div>
            </b-card>
          </div>
        </b-overlay>
      </div>
      <div v-if="activeTab === 'Creatives'">
        <b-card style="min-height: 50vh; max-height: auto justify-content: center; margin-left:-5px; margin-right:-5px">
          <div class="headerManagerContainer">
            <div class="cardContainerForSubHeader">
              <div style="font-size: large; color: #000000; font-weight: bold; font-size: 20px; display: flex; gap:2px">
                <feather-icon icon="SunIcon" color="red" size="30" class="mr-1"
                  style="margin-top: -1px;" /><span>Creatives</span>
              </div>
            </div>
            <div>
              <div class="creativesSubHeader">
                <div style="margin-top: 12px;">Clients:</div>
                <div class="SelectBoxContainer">
                  <div class="form-group form-container1">
                    <div class="select-container1">
                      <select style="margin-top: 3px;" v-model="selectedSummary" class="gmb-client-select"
                        @change="getCreativesDataOnchange">
                        <option value="">Select Client</option>
                        <option v-for="onetype in fetchClientsList" class="form-control" id="lg_type" name="lg_type"
                          :value="[onetype._id, onetype.name]">
                          {{ onetype.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div style="display: flex; gap:10px">
                  <div style="margin-top: 12px;">Dealers:</div>
                  <div style="margin-top: 2px;">
                    <search-select v-model="selectedCreativesDealers" :options="getDealersDataItem"
                      :filter-function="applySearchFilter" :type="creativesUploadFilterType"></search-select>
                  </div>
                </div>
                <div>
                  <b-button variant="link" @click="handleButtonClick" style="margin-top: -1px;">
                    <feather-icon icon="UploadIcon" size="15" /> Upload
                  </b-button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isCreativesLoadProperly"
            style="text-align: center; justify-content: center; align-items:  center; margin-left: 0%; right:auto ; margin-top:10% ;">
            <b-spinner large> </b-spinner>
          </div>
          <div v-if="paginatedCreativesDataLists.length === 0"
            style="text-align: center; justify-content: center; align-items:  center; margin-left: 0%; right:auto ; margin-top:10% ;">
            No creative(s) found for selected dealer.
          </div>
          <div v-else class="creativesManagerContainer">
            <div v-for="(item, index) in paginatedCreativesDataLists" style="padding: 10px; " :key="index">
              <div class="image-container-list" @mouseover="showCancelButton" @mouseleave="hideCancelButton">
                <div class="creativesListImageContainer">
                  <img :src="item.s3URL" :alt="index" class="creativesListImage" />
                </div>
                <b-button
                  style="margin-top:-25px;margin-right:-20px; background: linear-gradient(to right, rgb(80, 86, 92), rgb(153 153 153)); height:40px; width:40px !important"
                  v-if="isHovered" class="cancel-button" @click="deleteCreativesPost(item.s3URL, item._id)">
                  <span v-if="isCreativesDelete">
                    <b-spinner small> </b-spinner>
                  </span>
                  <span v-else>
                    <feather-icon size="20" icon="XIcon" color="white" style="margin-bottom: 3px;" />
                  </span>
                </b-button>
              </div>
              <div class="mt-1" style="color: black;">
                <h6 style="color: black;">{{ item.dealer }}</h6>
                <p>
                  Date :
                  {{
                    new Date(item.createdAt)
                      .toISOString()
                      .split("T")[0]
                      .split("-")
                      .reverse()
                      .join("-")
                  }}
                </p>
              </div>
            </div>
          </div>
          <b-pagination v-show="creativesDataLists.length > 0" v-model="currentPage"
            :total-rows="creativesDataLists.length" :per-page="itemsPerPage" class="mt-1" first-number last-number
            align="right" prev-class="prev-item" next-class="next-item"></b-pagination>
        </b-card>
      </div>
      <div v-if="activeTab === 'Incoming'">
        <b-card style="min-height: 50vh; max-height: auto justify-content: center; margin-left:-5px; margin-right:-5px">
          <div class="headerManagerContainer">
            <div class="cardContainerForSubHeader">
              <div style="font-size: large; color: #000000; font-weight: bold; font-size: 18px; display: flex; gap:2px">
                <feather-icon icon="SunIcon" color="red" size="30" class="mr-1" /><span class="respondTest">WhatsApp
                  Responses from Dealers</span>
              </div>
            </div>
            <div style="display:flex; gap:10px; margin-bottom:-5px; margin-top:5px">
              <div class="creativesSubHeaderSummary">
                <div style="margin-top: 10px;">Clients:</div>
                <div class="SelectBoxContainer">
                  <div class="form-group form-container1" style="width:200px;">
                    <div class="select-container1">
                      <select v-model="selectedSummary" class="gmb-client-select" @change="getResponseData">
                        <option value="">Select Client</option>
                        <option v-for="onetype in fetchClientsList" :value="[onetype._id, onetype.name]"
                          class="form-control" id="lg_type" name="lg_type" v-bind:key="onetype._id">
                          {{ onetype.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="creativesSubHeader">
                <div style="display: flex; gap:10px">
                  <div style="margin-top: 10px;">Dealers:</div>
                  <div>
                    <search-select v-model="selectedResponseCreativesDealers" :options="getDealersResponseDataItem"
                      :filter-function="applySearchResponseFilter" :type="creativesUploadFilterType"></search-select>
                  </div>

                </div>
              </div>
              <div>
                <b-button @click="refreshRespondDealersPage" variant="link" v-b-tooltip.hover.top="'Refresh'">
                  <feather-icon icon="RefreshCwIcon" size="14" />
                </b-button>
              </div>
            </div>
          </div>
          <div v-if="isResponseLoad"
            style="text-align: center; justify-content: center; align-items:  center; margin-left: 0%; right:auto ; margin-top:10% ;">
            <b-spinner large> </b-spinner>
          </div>
          <div v-else-if="this.paginatedResponseDataLists.length == 0"
            style="text-align: center; justify-content: center; align-items:  center; margin-left: 0%; right:auto ; margin-top:10% ; height: 20vh;">
            Dealer Has Not Responded Yet.
          </div>
          <div v-else>
            <table class="my-table">
              <thead>
                <tr style="font-size: 13px;">
                  <th style="border: 2px solid #dadada;">From Mobile Number</th>
                  <th style="border: 2px solid #dadada;">WhatsApp Message</th>
                  <th style="border: 2px solid #dadada;">Received At</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in this.paginatedResponseDataLists" :key="index" style="font-size:12px">
                  <td style="border: 1px solid #dadada;padding: 5px;">
                    {{ item.from }}
                  </td>
                  <td v-b-tooltip.hover.x="item.content"
                    style="border: 1px solid #dadada; padding-left:20px;padding-right:20px" v-if="item && item.content">
                    {{ breakText(item.content) }}
                  </td>
                  <td style="border: 1px solid #dadada;">
                    {{ new Date(item.receivedAt).toLocaleString('en-GB', {
                      day: '2-digit', month: '2-digit', year:
                        'numeric', hour: 'numeric', minute: 'numeric', hour12: true
                    }) }}
                  </td>

                </tr>
              </tbody>
            </table>
          </div>
          <b-pagination v-if="this.paginatedResponseDataLists.length > 0" v-model="currentResponsePage"
            :total-rows="responseMessageData.length" :per-page="itemsResponsePerPage" class="mt-1" first-number
            last-number align="right" prev-class="prev-item" next-class="next-item"></b-pagination>
        </b-card>

      </div>
    </b-overlay>
    <b-modal v-model="isOpenUploadDealersModal" hide-footer hide-header centered>
      <div class="modal-item" style="display: flex; justify-content: space-between;">
        <div class="pr-1 pt-0 edit-form" style="margin-top: 10px;">
          <h3 class="app-title sm">
            Upload Dealers CSV
          </h3>
        </div>
        <div>
          <b-button @click="isOpenUploadDealersModalClose" variant="flat-primary"><feather-icon
              icon="XIcon"></feather-icon></b-button>
        </div>
      </div>
      <div class="mb-6 pt-2" style="display: flex; justify-content: space-between;">
        <div style="border:1px solid #c2c2c2; padding: 5px; border-radius: 5px;">
          <input type="file" id="csvFile" ref="csvFile" accept=".csv" required />
        </div>
        <div>
          <b-button @click="uploadDealersCSV" variant="primary">Upload <b-spinner small
              v-if="isUploadCSV"></b-spinner></b-button>
        </div>
      </div>
    </b-modal>
    <b-modal v-model="isOpenAlloctedBudgetDealersModal" hide-footer hide-header centered size="xs">
      <div class="modal-item" style="display: flex; justify-content: space-between;">
        <div class="pr-1 pt-0 edit-form" style="margin-top: 10px;">
          <h3 class="app-title sm">
            <span>{{
              this.alocatedBudget > 0
              ? "Update Allocated Budget"
              : "Allocate Budget"
            }}</span>
          </h3>
        </div>
        <div>
          <b-button @click="isOpenAlloctedBudgetDealersModalDealersClose" style="margin-top:-11px; margin-right:-20px"
            variant="flat-light"><feather-icon icon="XIcon"></feather-icon></b-button>
        </div>
      </div>
      <b-row class="p-1">
        <b-col cols="12">
          <div>
            <label for="client-name">Dealer Name</label>
            <input v-model="dealersName" placeholder="Jaqaur" class="budgetAllocationInput budgetAllocationInputName"
              style="width:250px" />
          </div>
        </b-col>
        <b-col cols="12" class="mt-1">
          <div>
            <label for="client-name">Mobile Number</label>
            <input v-model="dealersMobileNumber" placeholder="Mobile Number"
              class="budgetAllocationInput budgetAllocationInputName" style="width:250px" />
          </div>
        </b-col>
        <b-col cols="12">
          <div class="alignment-container">
            <div class="equal-size form-fields ">
              <b-form-group>
                <label for="client-name">Budget <span class="validation">*</span></label>
                <b-input-group>
                  <b-form-input v-model="dealersBudget" id="client-name" placeholder="Enter Your Budget"
                    class="budgetAllocationInput" />
                  <b-input-group-append is-text>
                    ₹ | $
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
        </b-col>
      </b-row>

      <div class="mt-2">
        <b-button @click="updateDealersData" variant="primary" block><span>{{ this.alocatedBudget > 0 ? "Update" :
          "Submit" }}</span>
          <b-spinner small v-if="isUpdateDealersData" class="ml-1"></b-spinner></b-button>
      </div>
    </b-modal>
    <b-modal v-model="isOpenCampaignsModal" hide-footer hide-header centered size="xs">
      <div class="modal-item" style="display: flex; justify-content: space-between;">
        <div class="pr-1 pt-0 edit-form" style="margin-top: 10px;">
          <h3 class="app-title sm">
            <span>
              Add Campaign Duration
            </span>
          </h3>
        </div>
        <div>
          <b-button @click="isCloseCampaigns" style="margin-top:-11px; margin-right:-20px"
            variant="flat-light"><feather-icon icon="XIcon"></feather-icon></b-button>
        </div>
      </div>
      <b-row style="margin-left:10px">
        <b-col cols="12" class="mt-1">
          <label for="client-name">Dealer Name</label>
          <input v-model="campaignDelaerName" disabled placeholder="Jaqaur"
            class="budgetAllocationInput budgetAllocationInputName" style="font-size:11px" />
        </b-col>
        <b-col cols="12" class="mt-1">
          <label for="client-name">Mobile Number</label>
          <input v-model="campaignDelaerNumber" disabled placeholder="Mobile Number"
            class="budgetAllocationInput budgetAllocationInputName" style="font-size:11px" />
        </b-col>
      </b-row>
      <div style="margin-left:28px">
        <b-row>
          <b-col cols="12">
            <label for="clientSelect" class="form-label mt-1" style="font-size:10px">From {{
              this.dateRange.startDate | date }} to
              {{ this.dateRange.endDate | date }}</label>
            <br />
            <date-range-picker ref="picker" opens="right" :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
              :showWeekNumbers="false" :showDropdowns="true" :autoApply="true" v-model="dateRange" @update="updateValues"
              :dateFormat="dateFormat" control-container-class="picker-controller" :ranges="dateRanges"
              :auto-apply="false" :readonly="false" class="filterCardSelect">
              <template v-slot:input="picker">
                <feather-icon icon="CalendarIcon" size="16" class="mr-1"></feather-icon>
                {{ formatDateDisplay(picker.startDate) }} -
                {{ formatDateDisplay(picker.endDate) }}
              </template>
            </date-range-picker>
            <span class="error-text">{{ this.campaignDurationErrorText }}</span>
          </b-col>
        </b-row>
      </div>
      <div class="mt-2">
        <b-button @click="sendCampaign" variant="primary" block><span>Submit</span>
          <b-spinner small v-if="this.isCampaignsSend" class="ml-1"></b-spinner></b-button>
      </div>
    </b-modal>
    <b-modal v-model="isOpenCreativesUploadModal" hide-footer hide-header centered>
      <div class="modal-item" style="display: flex; justify-content: space-between;">
        <div class="pr-1 pt-0 edit-form" style="margin-top: 10px;">
          <h3 class="app-title sm">
            Upload Creatives
          </h3>
        </div>
        <div>
          <b-button @click="isCloseCreativesUploadModal" variant="flat-primary"
            style="margin-top:-11px; margin-right:-20px"><feather-icon icon="XIcon"></feather-icon></b-button>
        </div>
      </div>
      <b-row>
        <b-col cols="12">
          <div class="SelectBoxContainer mt-1" style="padding-left:9px; padding-right:9px">
            <label for="client-name">Select Client<span class="text-danger">*</span></label>
            <div class="form-group form-container1" style="width:100%">
              <div class="select-container1">
                <select v-model="selectedSummary" class="gmb-client-select" @change="getCreativesDataOnchange">
                  <option value="">Select Client</option>
                  <option v-for="onetype in fetchClientsList" class="form-control" id="lg_type" name="lg_type"
                    :value="[onetype._id, onetype.name]">
                    {{ onetype.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <div class="SelectBoxContainer " style="padding-left:9px; padding-right:9px">
            <label for="client-name">Select Dealer<span class="text-danger">*</span></label>
            <div class="form-group form-container1" style="width:100%">
              <div class="select-container1">
                <search-select v-model="selectedCreativesDealers" :options="getDealersDataItem"
                  :filter-function="applySearchFilter" :type="creativesUploadType"></search-select>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-form-group>
        <label for="client-name">Upload Image<span class="text-danger">*</span></label>
        <br />
        <span v-if="this.imageUploadWarning" class="text-danger" style="font-size: 12px;">Invalid file type. Only JPEG and
          PNG files are allowed.</span>
        <div class="image-container">
          <div class="grid-container" style="margin-left:15px">
            <div class="image-grid-creatives">
              <div class="file-input-container cardUploadCreatives" style="margin-top: 5px;">
                <input type="file" class="file-input" id="fileInput" @change="previewImage" ref="fileInput" multiple
                  accept="image/*" />
                <label for="fileInput" class="custom-button">
                  <span class="icon">
                    <feather-icon icon="UploadCloudIcon" size="20" color="blue"
                      style="margin-top: 20px; margin-left: 18px;"></feather-icon>
                  </span>
                  Select Files
                </label>
              </div>
              <div class="image-previews" style="padding:-5px; margin-left: 10px;">
                <div v-for="(preview, index) in imagePreviews.slice(0, 3)" :key="index" class="image-preview-container"
                  style="position: relative; display: inline-block; margin-right:0px; margin-left: 5px;">
                  <img :src="preview" alt="Image Preview" class="image-preview-creatives" />
                  <button @click="removeFile(index)" class="remove-button"
                    style="position: absolute; top: 2px; right: 0px; z-index: 50; border: none; background: black; border-radius:50px; opacity: 0.9; ">
                    <feather-icon icon="XIcon" size="11" color="white" style="margin-top: -2px"></feather-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-container" style="margin-left:15px; margin-top: -20px;">
            <div class="image-grid-creatives">
              <div style="display: grid;
  grid-template-columns: repeat(4, 1fr); ">
                <div v-for="(preview, index) in imagePreviews.slice(3, imagePreviews.length)" :key="index"
                  class="image-preview-container"
                  style="position: relative; display: inline-block; margin-right:0px; margin-left: 5px;">
                  <img :src="preview" alt="Image Preview" class="image-preview-creatives" />
                  <button @click="removeFile(index)" class="remove-button"
                    style="position: absolute; top: 2px; right: 0px; z-index: 50; border: none; background: black; border-radius:50px; opacity: 0.9; ">
                    <feather-icon icon="XIcon" size="11" color="white" style="margin-top: -2px"></feather-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p v-if="fileSizeError" class="error-message">
          {{ fileSizeError }}
        </p>

        <div
          style=" margin-top:5px;width: 100%; background-color: #c9c9c948; border: 1px solid lightgray; font-size: 12px;">
          <ul class="bullet-list">
            <li>
              <b>Only 20 images are allowed to be added as creatives.</b>
            </li>
            <li>
              <b>Individual image size should not be exceed more than 2MB.</b>
            </li>
            <li>
              <b>Same image is not allowed.</b>
            </li>
            <li>
              <b>Image size with minimum value 250px/250px(w/h) is allowed.</b>
            </li>
          </ul>
        </div>
      </b-form-group>
      <div style="float: right;">
        <b-button variant="primary" :disabled="isBtnDisabled" @click="uploadCreatives">
          Upload <feather-icon icon="UploadIcon" size="15" /><b-spinner small v-if="isCreativesUpload">
          </b-spinner>
        </b-button>
      </div>
    </b-modal>
    <b-modal v-model="isOpenApprovalDealersModal" hide-footer hide-header centered id="my-modal">
      <div class="modal-item" style="display: flex; justify-content: space-between;">
        <div class="pr-1 pt-0 edit-form" style="margin-top: 10px;">
          <h3 class="app-title xs">
            Preview of WhatsApp Message for Creatives Approval
          </h3>
        </div>
        <div>
          <b-button @click="closeApprovalModal" variant="flat-primary"
            style="margin-top:-11px; margin-right:-20px"><feather-icon icon="XIcon"></feather-icon></b-button>
        </div>
      </div>
      <div class="mb-6 pt-2">
        <b-form-group>
          <label for="client-name">Creatives<span class="text-danger">*</span></label>
          <div class="image-container">
            <div class="grid-container" style="display: flex; flex-direction: row;">
              <div class="image-grid-creatives-approval">
                <div v-for="(item, key) in this.creativesDataLists" :key="key" class="image-preview-container"
                  style="position: relative; display: inline-block; margin-right:0px; margin-left: 5px;">
                  <img :src="item.s3URL" alt="Image Preview" class="image-preview-creatives" />
                  <button v-if="creativesDataLists.length > 1" @click="removeApprovalFile(key)" class="remove-button"
                    style="position: absolute; top: 2px; right: 6px; z-index: 50; border: none; background: black; border-radius:50px; opacity: 0.7; ">
                    <feather-icon icon="XIcon" size="11" color="white" style="margin-top: -2px"></feather-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-form-group>
        <b-form-group>
          <label for="client-name">Dealer's Name <span class="text-danger">*</span></label>
          <br />
          <div style="background-color:rgb(232, 231, 231); padding:5px; border-radius:5px">
            <span style="margin-left: 5px;">{{ this.ApprovalDelearName }}</span>
          </div>
        </b-form-group>
        <b-form-group>
          <label for="client-name">Mobile Number <span class="text-danger ">*</span></label>
          <br />
          <div style="background-color:rgb(232, 231, 231); padding:5px; border-radius:5px">
            <span style="margin-left: 5px;">{{
              this.ApprovalDelearNumber
            }}</span>
          </div>
        </b-form-group>
        <div style="float: right;">
          <b-button variant="primary" @click="getDelearApproval">
            Send <feather-icon icon="SendIcon" size="15" />
            <b-spinner small v-if="this.isGetApprovalSend"></b-spinner>
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal id="bv-modal-example" hide-footer centered v-model="showConfirmationDeleteCreativesModal">
      <div class="d-block text-center">
        <h4>
          Are you sure you want to delete this creative? <br />
          <span style="font-size:15px">
            This action cannot be undone!
          </span>
        </h4>
      </div>
      <div style="display :flex ; justify-content: space-evenly;  align-items: center;">
        <b-button style="min-width: 180px" class="mt-2" @click="$bvModal.hide('bv-modal-example')">Cancel</b-button>
        <b-button class="mt-2" style="min-width: 180px" variant="danger" @click="deleteCreativesPostAfterConfirmation()"
          :disabled="disabledDeleteButton">
          Delete
          <span v-if="this.isCreativesDelete">
            <b-spinner small></b-spinner>
          </span>
        </b-button>
      </div>
    </b-modal>
    <b-modal id="bv-modal-example" style="margin-top: 100px;" hide-footer hide-header v-model="isImagePreview" centered>
      <div class="modal-container">
        <b-button @click="getPreviewClose" class="close-button"
          style="background: linear-gradient(to right, rgb(69 74 78), rgb(153 153 153))">
          <feather-icon icon="XIcon" size="15"></feather-icon>
        </b-button>
        <img :src="previewImageDealer" alt="Preview Image" class="centered-image" />
      </div>
    </b-modal>
    <b-modal id="bv-modal-example" style="margin-top: 100px;" hide-footer hide-header v-model="isImagePreviewLimit"
      centered>
      <div class="modal-container">
        <h5 class="m-1 mb-1 text-center">
          Maximum 20 items are allowed.<br />
          <span style="font-size: 13px;">
            To upload a new image, delete one from the existing set.
          </span>
        </h5>
        <div style="display: flex; justify-content: center;">
          <b-button variant="primary" @click="creativesImageLimitClose">
            Ok
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal id="bv-modal-example" style="margin-top: 100px;" hide-footer hide-header v-model="isConfirmInfo" centered>
      <div class="modal-item" style="display: flex; justify-content: space-between;">
        <div class="pr-1 pt-0 edit-form" style="margin-top: 10px;">
          <h3 class="app-title xs">
            Send WhatsApp Message for Basic Info Confirmation
          </h3>
        </div>

        <div>
          <b-button @click="closeIsConfirmModal" variant="flat-primary"
            style="margin-top:-11px; margin-right:-20px"><feather-icon icon="XIcon"></feather-icon></b-button>
        </div>
      </div>
      <div style="padding: 10px;">
        <b-row>
          <b-col cols="12">
            <label> Owner's Name <span style="color: red;">*</span> </label>
            <b-form-input type="text" placeholder="Add a Number" rows="3" v-model="ownerName" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="mt-1">
            <label> Dealer's Address <span style="color: red;">*</span> </label>
            <b-form-textarea id="textarea-lazy" placeholder="Add a Description" rows="3" v-model="dealerAdress"
              @input="updateCharacterCount"></b-form-textarea>
            <span style="font-size:11px; float:right; margin-top:2px">{{ characterCount }}/300</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <label> Dealer's Number <span style="color: red;">*</span> </label>
            <b-form-input type="number" placeholder="Add a Number" rows="3" v-model="dealerNum" />
          </b-col>
        </b-row>
        <div style="margin-top: 15px; margin-left: -10px; margin-bottom: -5px;">
          <label>Working Hours <span style="color: red;">*</span></label>
        </div>
        <b-row>
          <b-col cols="6">
            <div class="time-selector">
              <label>From</label>
              <select id="timeSelect" v-model="selectedTime" class="select">
                <option v-for="time in times" :key="time" :value="time">{{
                  time
                }}</option>
              </select>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="time-selector">
              <label>To</label>
              <select id="timeSelect" v-model="selectedEndTime" class="select">
                <option v-for="time in times" :key="time" :value="time">{{
                  time
                }}</option>
              </select>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12">
            <label>Instagram Link <span style="color: red;">*</span></label>
            <br />
            <div>
              <b-form-input v-model="instagramLink" />
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12">
            <label>Google Listing Link <span style="color: red;">*</span></label>
            <br />
            <b-form-input v-model="googleMapsLink" />
            <div></div>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="9"> </b-col>
          <b-col cols="3">
            <div style="float:right">
              <b-button style="width:150px" variant="primary" @click="basicInfoConfirmation('basicInfo', [])"
                :disabled="isBasicInfoConfirmationDisabled">
                Send <feather-icon size="17" icon="SendIcon" />
                <b-spinner style="margin-left: 5px;" small v-if="isBasicInfoConfirm"></b-spinner>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal class="previewHidden" id="bv-modal-example" style="margin-top: 100px;" hide-footer hide-header
      v-model="isConfirmInfoPreview" centered>
      <div class="modal-item" style="display: flex; justify-content: space-between;">
        <div class="pr-1 pt-0 edit-form" style="margin-top: 10px;">
          <h3 class="app-title xs">
            Preview of Whatsapp Message Sent for Confirmation
          </h3>
        </div>

        <div>
          <b-button @click="closePreviewBasicInfo" variant="flat-primary"
            style="margin-top:-11px; margin-right:-20px"><feather-icon icon="XIcon"></feather-icon></b-button>
        </div>
      </div>
      <div style="padding: 10px;">
        <b-row>
          <b-col cols="12">
            <label> Owner's Name <span style="color: red;">*</span> </label>
            <b-form-input type="text" placeholder="Add a Number" rows="3" v-model="previewOwener"
              :disabled="this.checkForApproval == 'APPROVED'" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" style="margin-top: 15px;">
            <label> Dealer's Address <span style="color: red;">*</span> </label>
            <b-form-textarea :disabled="this.checkForApproval == 'APPROVED'" id="textarea-lazy"
              placeholder="Add a Description" rows="3" v-model="previewAddress"
              @input="updateCharacterCount"></b-form-textarea>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" style="margin-top: 15px;">
            <label> Dealer's Number <span style="color: red;">*</span> </label>
            <b-form-input type="number" placeholder="Add a Number" rows="3" v-model="previewNo"
              :disabled="this.checkForApproval == 'APPROVED'" />
          </b-col>
        </b-row>
        <div style="margin-top: 15px; margin-left: -10px; margin-bottom: -5px;">
          <label>Working Hours <span style="color: red;">*</span></label>
        </div>
        <b-row>
          <b-col cols="6">
            <div class="time-selector">
              <label>From</label>
              <select id="timeSelect" :disabled="this.checkForApproval == 'APPROVED'" v-model="previewStartTime"
                class="select">
                <option v-for="time in times" :key="time" :value="time">{{
                  time
                }}</option>
              </select>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="time-selector">
              <label>To</label>
              <select id="timeSelect" :disabled="this.checkForApproval == 'APPROVED'" v-model="previewEndTime"
                class="select">
                <option v-for="time in times" :key="time" :value="time">{{
                  time
                }}</option>
              </select>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12">
            <label>Instagram Link <span style="color: red;">*</span></label>
            <br />
            <div>
              <b-form-input v-model="previewInstagramLink" :disabled="this.checkForApproval == 'APPROVED'" />
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12">
            <label>Google Listing Link <span style="color: red;">*</span></label>
            <br />
            <b-form-input v-model="previewGoogleLink" :disabled="this.checkForApproval == 'APPROVED'" />
            <div></div>
          </b-col>
        </b-row>
        <b-row class="mt-1"
          v-if="this.checkForApproval == 'SENT' || this.checkForApproval == 'DENIED' || this.checkForApproval == 'REMINDER_SENT' || this.checkForApproval == 'FAILED'">
          <b-col cols="9"> </b-col>
          <b-col cols="3">
            <div style="float:right">
              <b-button style="width:150px" variant="primary" @click="basicInfoConfirmation('resend', [])">
                Resend <feather-icon size="17" icon="SendIcon" />
                <b-spinner style="margin-left: 5px;" small v-if="isBasicInfoConfirm"></b-spinner>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal v-model="isOpenStatusUploadModal" hide-footer hide-header centered>
      <div class="modal-item" style="display: flex; justify-content: space-between;">
        <div class="pr-1 pt-0 edit-form" style="margin-top: 10px;">
          <h3 class="app-title xs">
            {{ this.dealerWhatappsName }}'s Whatsapp History
          </h3>
        </div>
        <div>
          <b-button @click="closeStatusModal" style="margin-top:-11px; margin-right:-20px"
            variant="flat-light"><feather-icon icon="XIcon"></feather-icon></b-button>
        </div>
      </div>
      <div class="p-2">
        <div v-if="this.statusData.length == 0 && this.statusBasicData.length == 0" class="p-4 text-center">
          Whatsapp History not found.
        </div>
        <div v-else>
          <stepper :steps="this.statusData" :activeSteps="activeSteps" :stepsBasic="this.statusBasicData" />
        </div>

      </div>
    </b-modal>
    <b-modal v-model="isOpenApprovalDealersPreviewModal" hide-footer hide-header centered id="my-modal">
      <div class="modal-item" style="display: flex; justify-content: space-between;">
        <div class="pr-1 pt-0 edit-form" style="margin-top: 10px;">
          <h3 class="app-title xs">
            Preview of WhatsApp Message for Creatives Approval
          </h3>
        </div>
        <div>
          <b-button @click="closeApprovalPreviewModal" variant="flat-primary"
            style="margin-top:-11px; margin-right:-20px"><feather-icon icon="XIcon"></feather-icon></b-button>
        </div>
      </div>
      <div class="mb-6 pt-2">
        <b-form-group>
          <label for="client-name">Creatives<span class="text-danger">*</span></label>
          <div class="image-container">
            <div class="grid-container" style="display: flex; flex-direction: row;">
              <div class="image-grid-creatives-approval">
                <div v-for="(item, key) in this.creativesPreview" :key="key" class="image-preview-container"
                  style="position: relative; display: inline-block; margin-right:0px; margin-left: 5px;">
                  <img :src="item.imgURL" alt="Image Preview" class="image-preview-creatives" />
                </div>
              </div>
            </div>
          </div>
        </b-form-group>
        <b-form-group>
          <label for="client-name">Dealer's Name <span class="text-danger">*</span></label>
          <br />
          <div style="background-color:rgb(232, 231, 231); padding:5px; border-radius:5px">
            <span style="margin-left: 5px;">{{ this.creativesDealerName }}</span>
          </div>
        </b-form-group>
        <b-form-group>
          <label for="client-name">Mobile Number <span class="text-danger ">*</span></label>
          <br />
          <div style="background-color:rgb(232, 231, 231); padding:5px; border-radius:5px" disabled>
            <span style="margin-left: 5px;">{{
              this.creativesDealerNo
            }}</span>
          </div>
        </b-form-group>
      </div>
    </b-modal>
    <b-modal v-model="isOpenReminderModal" hide-footer hide-header centered id="my-modal" size="xs">
      <div class="modal-item" style="display: flex; justify-content: space-between;">
        <!-- <div class="pr-1 pt-0 edit-form" style="margin-top: 10px;">
          <h3 class="app-title xs">
            Reminder Send
          </h3>
        </div> -->
        <div></div>
        <div style=" translate: 0px -50px;">
          <div
            style="background-color: #6c4e96; height:90px; margin-left:auto; margin-right:auto; width:90px; border-radius:60px">
            <div style="text-align: center;">
              <b-icon icon="bell-fill" style="margin-top: 27px;" font-scale="2.4" color="#ffffff" />
            </div>
          </div>
        </div>
        <div></div>
        <!-- <div>
          <b-button @click="closeRemindModal" variant="flat-primary"
            style="margin-top:-11px; margin-right:-20px"><feather-icon icon="XIcon"></feather-icon></b-button>
        </div> -->
      </div>
      <div
        style="text-align:center; align-items: center; justify-content: center;font-size: 16px; margin-top:-30px; font-weight: bolder; color: #7c8691;">
        <span v-if="this.remindTypeCheck == 'basic info'">
          Basic Info Approval
        </span>
        <span v-if="this.remindTypeCheck == 'creatives'">
          Creatives Approval
        </span>
      </div>
      <div style="text-align:center; align-items: center; justify-content: center;font-size: 12px; color: #c0c0c0;">
        <span>
          <strong>
            Are you sure about sending dealer a reminder?
          </strong>
        </span>
      </div>
      <div class="mb-6 pt-2">
        <div
          style="display:flex;justify-content:space-around; gap:1rem; margin-left:auto; margin-right:auto; width:80px;">
          <b-button variant="danger" class="text-xs" size="lg" @click="closeRemindModal">
            Cancel
          </b-button>
          <b-button variant="primary" class="text-xs" size="lg" @click="sendReminder">
            <div style="display: flex; gap:2px">
              <span> Remind</span> <b-spinner small v-if="isSendReminder"></b-spinner>
            </div>
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal v-model="previewOfNonResponfingFlag" hide-footer hide-header centered id="my-modal" size="sm">
      <div class="pr-0 pt-0 edit-form" style="margin-top: 10px;">
        <h3 class="app-title xs text-center">
          Non Responding Dealer's preview
        </h3>
      </div>
      <div>
        <b-form-group v-if="this.nonRespondingPreview == 'creatives'">
          <label for="client-name">Creatives<span class="text-danger">*</span></label>
          <div class="image-container">
            <div class="grid-container" style="display: flex; flex-direction: row;">
              <div class="image-grid-unrespond-creatives-approval">
                <div v-for="(item, key) in this.nonRespondingCreatives" :key="key" class="image-preview-container"
                  style="position: relative; display: inline-block; margin-right:0px; margin-left: 5px;">
                  <img :src="item" alt="Image Preview" class="image-preview-creatives" />
                </div>
              </div>
            </div>
          </div>
        </b-form-group>
        <b-form-group>
          <label for="client-name">Delaer's Name </label>
          <br />
          <div style="background-color:rgb(232, 231, 231); padding:5px; border-radius:5px" disabled>
            <span style="margin-left: 5px; font-size: 12px;">{{ this.nonRespondingDealerName }}</span>
          </div>
        </b-form-group>
        <b-form-group v-if="this.nonRespondingPreview == 'basic'">
          <label for="client-name">Address </label>
          <br />
          <div style="background-color:rgb(232, 231, 231); padding:5px; border-radius:5px" disabled>
            <span style="margin-left: 5px; font-size: 12px;">{{ this.nonRespondingAddress }}</span>
          </div>
        </b-form-group>

        <b-form-group>
          <label for="client-name">Owner Name </label>
          <br />
          <div style="background-color:rgb(232, 231, 231); padding:5px; border-radius:5px" disabled>
            <span style="margin-left: 5px; font-size: 12px;">{{ this.nonRespondingOwnerName }}</span>
          </div>
        </b-form-group> <b-form-group>
          <label for="client-name">Mobile Number </label>
          <br />
          <div style="background-color:rgb(232, 231, 231); padding:5px; border-radius:5px" disabled>
            <span style="margin-left: 5px;font-size: 12px;">{{ this.nonRespondingNumber }}</span>
          </div>
        </b-form-group>
        <b-form-group v-if="this.nonRespondingPreview == 'basic'">
          <label for="client-name">Working Hours</label>
          <br />
          <div style="background-color:rgb(232, 231, 231); padding:5px; border-radius:5px" disabled>
            <span style="margin-left: 5px;font-size: 12px;">{{ this.workingHoursUnresponding }}</span>
          </div>
        </b-form-group>
        <b-row class="mt-1" v-if="this.nonRespondingPreview == 'basic'">
          <b-col cols="12">
            <label>Instagram Link </label>
            <br />
            <div>
              <b-form-input v-model="nonRespondingInstagram" disabled />
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-1" v-if="this.nonRespondingPreview == 'basic'">
          <b-col cols="12">
            <label>Google Listing Link</label>
            <br />
            <b-form-input v-model="nonRespondingGoogle" disabled />
            <div></div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BTabs,
  BTab,
  BCardTitle,
  BFormSelect,
  BCol,
  BRow,
  BAvatar,
  BIcon,
  BTable,
  BPagination,
  BOverlay,
  BModal,
  BCardText,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BFormTextarea,
  BInputGroupPrepend,
  BSpinner,
  BLink,
  BFormCheckbox
} from "bootstrap-vue";
import Stepper from "./Stepper.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import MultiColorTable from "../Client/MultiColorTable.vue";
import DateRangePicker from "vue2-daterange-picker";
import { getUserToken } from "@/auth/utils";
import VueApexCharts from "vue-apexcharts";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import axios from "axios";
import SearchSelect from "./SearchSelect.vue"
import GlobalDateRangeSelector from './GlobalDateRangeSelector.vue'
let creativesArrr = []
export default {
  data() {
    return {
      responseMessageData: [],
      isResponseLoad: false,
      itemsResponsePerPage: 10,
      currentResponsePage: 1,
      creativesUploadFilterType: "creatviesUpload",
      creativesUploadType: "creatviesFilter",
      conditionaLdealerID: '',
      conditionaLowner: '',
      conditionaLcompeconditionaLBusinessAddress: '',
      conditionaLmobile_number: '',
      conditionaLworkingHour: '',
      conditionaLinstagram: '',
      conditionaLgoogle: '',
      conditionaLclientId: '',
      nonRespondingPreview: "",
      nonRespondingCreatives: [],
      workingHoursUnresponding: "",
      nonRespondingGoogle: "",
      nonRespondingInstagram: "",
      nonRespondingAddress: "",
      remindsDealer: [],
      conditionRenderingReminderMessage: "",
      isSendReminder: false,
      remindType: "",
      remindTypeCheck: "",
      remindId: "",
      nonRespondingDealerName: "",
      nonRespondingOwnerName: "",
      nonRespondingNumber: "",
      previewOfNonResponfingFlag: false,
      currentUnRespondingPage: 1,
      itemsunRespondingPerPage: 5,
      isNonResponding: false,
      nonRespondingDealersList: [],
      isOpenReminderModal: false,
      weeklyReportEnabled: false,
      searchQueryIntoDealers: "",
      isOpenApprovalDealersPreviewModal: false,
      creativesPreview: null,
      creativesDealerName: "",
      creativesDealerNo: "",
      isCampaignsSend: false,
      creativesIntialCase: "",
      dateRange: {
        endDate: moment()
          .subtract(0, "days")
          .format("YYYY-MM-DD"),
        startDate: moment()
          .subtract(7, "days")
          .format("YYYY-MM-DD"),
      },
      statusData: [],
      statusBasicData: [],
      checkForApproval: "",
      campaignDelaersData: [],
      originalCreativesDataLists: [],
      campaignDelaerName: "",
      campaignDelaerID: "",
      campaignDelaerNumber: "",
      campaignDelaerBudget: "",
      isOpenCampaignsModal: false,
      previewBasicDetails: null,
      previewStartTime: "",
      previewEndTime: "",
      previewNo: 0,
      previewGoogleLink: "",
      previewInstagramLink: "",
      previewAddress: "",
      dealerWhatappsName: "",
      steps: ['Sent', 'Approved', 'Denied'],
      activeSteps: [0, 1],
      years: [{ label: "2023", value: 0 }, { label: "2024", value: 1 }],
      selectedYear: '2024',
      isOpenStatusUploadModal: false,
      isBasicInfoConfirm: false,
      ownerName: "",
      workingHoursForBasic: "",
      dealerOwnwerName: "",
      basicInfoDealerID: "",
      dealerIndivialID: "",
      dealerID: "",
      instagramLink: "https://www.instagram.com/",
      googleMapsLink: "https://www.google.co.in/",
      characterCount: 1,
      selectedDelearsData: new Date().getMonth(),
      campaignDurationErrorText: "",
      dealersMonthSelections: [
        { label: "January", value: 0 },
        { label: "February", value: 1 },
        { label: "March", value: 2 },
        { label: "April", value: 3 },
        { label: "May", value: 4 },
        { label: "June", value: 5 },
        { label: "July", value: 6 },
        { label: "August", value: 7 },
        { label: "September", value: 8 },
        { label: "October", value: 9 },
        { label: "November", value: 10 },
        { label: "December", value: 11 },
      ],
      budgetsMonths: [
        { label: "January", value: 0 },
        { label: "February", value: 1 },
        { label: "March", value: 2 },
        { label: "April", value: 3 },
        { label: "May", value: 4 },
        { label: "June", value: 5 },
        { label: "July", value: 6 },
        { label: "August", value: 7 },
        { label: "September", value: 8 },
        { label: "October", value: 9 },
        { label: "November", value: 10 },
        { label: "December", value: 11 },
      ],
      campaignsTimeTypes: ["1-DAY", "7DAYS", "14DAYS", "1MONTH"],
      selectedCampaignItem: "1-DAY",
      times: [
        "12:00 AM",
        "12:30 AM",
        "01:00 AM",
        "01:30 AM",
        "02:00 AM",
        "02:30 AM",
        "03:00 AM",
        "03:30 AM",
        "04:00 AM",
        "04:30 AM",
        "05:00 AM",
        "05:30 AM",
        "06:00 AM",
        "06:30 AM",
        "07:00 AM",
        "07:30 AM",
        "08:00 AM",
        "08:30 AM",
        "09:00 AM",
        "09:30 AM",
        "10:00 AM",
        "10:30 AM",
        "11:00 AM",
        "11:30 AM",
        "12:00 PM",
        "12:30 PM",
        "01:00 PM",
        "01:30 PM",
        "02:00 PM",
        "02:30 PM",
        "03:00 PM",
        "03:30 PM",
        "04:00 PM",
        "04:30 PM",
        "05:00 PM",
        "05:30 PM",
        "06:00 PM",
        "06:30 PM",
        "07:00 PM",
        "07:30 PM",
        "08:00 PM",
        "08:30 PM",
        "09:00 PM",
        "09:30 PM",
        "10:00 PM",
        "10:30 PM",
        "11:00 PM",
        "11:30 PM",
      ],
      selectedCreativesDealers: null,
      selectedResponseCreativesDealers: null,
      getDealersResponseDataItem: [],
      search: "",
      previewDealerName: "",
      previewOwener: "",
      previewOwenerId: "",
      dealerAdress: "",
      ownerCreativesName: "",
      dealerNum: 0,
      selectedTime: "08:00 AM",
      selectedEndTime: "12:00 PM",
      isConfirmInfo: false,
      isConfirmInfoPreview: false,
      basicInfo: [],
      prevMonth: "",
      prePrevMonth: "",
      selectedSummary: [],
      isImagePreviewLimit: false,
      individualDealerName: "",
      individualDealerCode: "",
      individualDataIndex: 0,
      individualData: [],
      isFetchedIndividualData: false,
      alocatedBudget: "",
      getDealersApprovaData: [],
      previewImageDealer: "",
      isImagePreview: false,
      isSummaryPageLoad: false,
      ApprovalImages: {},
      ApprovalDelearName: "",
      ApprovalDelearIndex: 0,
      ApprovalDelearNumber: 0,
      isGetApprovalSend: false,
      perPageDealers: 6,
      currentDealerPage: 1,
      isLoadClientListForGMBDealers: false,
      dealerIndex: 0,
      disabledDeleteButton: false,
      showConfirmationDeleteCreativesModal: false,
      deleteCreativesUrl: "",
      deleteCreativesId: "",
      isGetDealersData: false,
      getDealersDataItem: [],
      dealersMobileNumber: 0,
      dealersName: "",
      dealersBudget: 0,
      isUpdateDealersData: false,
      isUploadCSV: false,
      summaryNewDealerData: [],
      isCreativesUpload: false,
      isCreativesDelete: false,
      isHovered: false,
      filesSelected: false,
      imagePreviews: [],
      maxImageCount: 3,
      isOpenApprovalDealersModal: false,
      isUploadCreatives: false,
      currentPage: 1,
      itemsPerPage: 15,
      creativesDataLists: [],
      creativesDataListsModal: [],
      filePreviews: [],
      fileSizeError: "",
      selectedCreativesId: "",
      selectedDealersId: "",
      selectedCreativesUploadId: "",
      isCreativesLoadProperly: false,
      description: "",
      imageUploadWarning: false,
      selectedDealerType: "Ecommerce",
      dealerType: ["Ecommerce", "Leadgen", "Branding", "Apps"],
      selectedBudgetFreqItem: new Date().getMonth(),
      isOpenUploadDealersModal: false,
      isOpenAlloctedBudgetDealersModal: false,
      isOpenCreativesUploadModal: false,
      characterCount: 0,
      file: null,
      fileNames: [],
      selectAll: false,
      selectedItems: [],
      activeTab: "",
      newSummaryPageLoad: false,
      iClickedItem: false,
      fetchClientsList: [],
      tooltipText: "Change over previous month",
      tooltipRatioText: "previous month ratio",
      seriesSummaryNewCPI: [
        {
          name: "Paid SV",
          data: [0, 0],
        },
      ],
      seriesSummaryNewCPIIncremental: [
        {
          name: "Incremental SV",
          data: [0, 0],
        },
      ],
      seriesSummaryNewCPSV: [
        {
          name: "CPSV",
          data: [0, 0],
        },
      ],
      seriesSummaryNewCPICPISV: [
        {
          name: "CPISV",
          data: [0, 0],
        },
      ],
      optionsSummaryNew: {
        chart: {
          height: 350,
          type: "line",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          align: "left",
        },
        grid: {
          row: {
            colors: ["transparent", "transparent"],
            opacity: 0.5,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: ["Previous Month", "Current Month"],
        },
      },
      newSummaryPageLoad: false,
      getSummaryChartData: [],
      costPerIncrementalStoreVisitColor: "",
      costPerIncrementalStoreVisitCount: 0,
      costPerIncrementalStoreVisitCountFormatted: 0,
      costPerIncrementalStoreVisitCountPrevMonth: 0,
      costPerIncrementalStoreVisitCountPrevMonthFormatted: 0,
      costPerIncrementalStoreVisitDeltaPrevMonth: 0,
      costPerStoreVisitColor: "",
      costPerStoreVisitCount: 0,
      costPerStoreVisitCountFormatted: 0,
      costPerStoreVisitCountPrevMonth: 0,
      costPerStoreVisitCountPrevMonthFormatted: 0,
      costPerStoreVisitDeltaPrevMonth: 0,
      incrementalStoreVisitsColor: "",
      incrementalStoreVisitsCount: 0,
      incrementalStoreVisitsCountFormatted: 0,
      incrementalStoreVisitsCountPrevMonth: 0,
      incrementalStoreVisitsCountPrevMonthFormatted: 0,
      incrementalStoreVisitsDeltaPrevMonth: 0,
      storeVisitsColor: "",
      storeVisitsCount: 0,
      storeVisitsCountFormatted: 0,
      storeVisitsCountPrevMonth: 0,
      storeVisitsCountPrevMonthFormatted: 0,
      storeVisitsDeltaPrevMonth: 0,
      summaryNewData: [],
      SummaryNewTotalData: null,
      summaryNewDealerData: [],
      selectedClientSummary: "",
      selectedCreatives: [],
    };
  },
  components: {
    GlobalDateRangeSelector,
    BCard,
    BButton,
    BTabs,
    BTab,
    BCardTitle,
    BFormSelect,
    BCol,
    BRow,
    BAvatar,
    BIcon,
    BTable,
    apexchart: VueApexCharts,
    BPagination,
    BOverlay,
    MultiColorTable,
    BModal,
    BCardText,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormTextarea,
    BInputGroupPrepend,
    ToastificationContent,
    BSpinner,
    BLink,
    Stepper,
    DateRangePicker,
    BFormCheckbox,
    SearchSelect
  },
  filters: {
    date(val) {
      return val ? moment(val).format("DD-MM-YYYY") : "";
    },
  },
  computed: {
    isBasicInfoConfirmationDisabled() {
      return (
        this.dealerNum == 0 ||
        this.instagramLink == "" ||
        this.googleMapsLink == "" ||
        this.dealerAdress == ""
      );
    },
    paginatedCreativesDataLists() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.creativesDataLists.slice(startIndex, endIndex);
    },
    paginatedResponseDataLists() {
      const startIndex = (this.currentResponsePage - 1) * this.itemsResponsePerPage;
      const endIndex = startIndex + this.itemsResponsePerPage;
      return this.responseMessageData.slice(startIndex, endIndex);
    },
    paginatedNonRespondingDealersLists() {
      const startIndex = (this.currentUnRespondingPage - 1) * this.itemsunRespondingPerPage;
      const endIndex = startIndex + this.itemsunRespondingPerPage;
      return this.nonRespondingDealersList.slice(startIndex, endIndex);
    },
    selectedItemsCount() {
      return this.selectedItems.length;
    },
    isExceedingLimit() {
      return this.description.length > this.maxLength;
    },
    isBtnDisabled() {
      return this.imagePreviews.length === 0 || this.selectedCreativesId.dealerID == '';
    },
    totalDealers() {
      return Object.keys(this.getDealersDataItem).length;
    },
    totalUnRespondDealers() {
      return this.paginatedNonRespondingDealersLists.length;
    },
    paginatedDealers() {
      const startIndex = (this.currentDealerPage - 1) * this.perPageDealers;
      const endIndex = startIndex + this.perPageDealers;
      const allDealers = Object.values(this.getDealersDataItem);
      const filteredDealers = allDealers.filter((dealer) => {
        return (
          dealer.dealerName.toLowerCase().includes(this.searchQueryIntoDealers.toLowerCase()) ||
          dealer.ownerName.toLowerCase().includes(this.searchQueryIntoDealers.toLowerCase()) ||
          dealer.completeBusinessAddress.toLowerCase().includes(this.searchQueryIntoDealers.toLowerCase())
        );
      });

      const slicedDealers = filteredDealers.slice(startIndex, endIndex);

      if (this.creativesDataLists.length === 0) {
        return slicedDealers.map((dealer) => ({
          ...dealer,
          isCreativesUploaded: false,
        }));
      }

      if (this.creativesDataLists.length < 3) {
        return slicedDealers.map((dealer) => ({
          ...dealer,
          isCreativesLessThan: false,
        }));
      }

      return slicedDealers;
    },
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let last7thDay = new Date();
      last7thDay.setDate(today.getDate() - 7);
      last7thDay.setHours(0, 0, 0, 0);

      let last14thDay = new Date();
      last14thDay.setDate(today.getDate() - 14);
      last14thDay.setHours(0, 0, 0, 0);

      let last30thDay = new Date();
      last30thDay.setDate(today.getDate() - 30);
      last30thDay.setHours(0, 0, 0, 0);

      var thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      var thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      let ranges = {
        Today: [today, today],
        Yesterday: [yesterday, today],
        "Last 7 Days": [last7thDay, yesterday],
        "Last 14 Days": [last14thDay, yesterday],
        "Last 30 Days": [last30thDay, yesterday],
        "Last Month": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "This Month": [thisMonthStart, thisMonthEnd],
        "This Year": [new Date(today.getFullYear(), 0, 1), new Date(today)],
      };

      return ranges;
    },
  },
  watch: {
    paginatedCreativesDataLists(newValue, oldValue) {
      // Handle changes to paginatedCreativesDataLists
    },
    selectedCreativesDealers(newValue, oldValue) {
      if (newValue && newValue.dealerID) {
        this.getCreativesData(newValue.dealerID)
      }
    },
    selectedResponseCreativesDealers(newValue, oldValue) {
      if (newValue && newValue.dealerID) {
        this.getResponseList(newValue.dealerID)
      }
    }
  },
  methods: {
    breakText(text) {
      const maxLength = 180; // You can adjust this value based on your requirements
      if (text.length <= maxLength) {
        return text;
      } else {
        return text.slice(0, maxLength) + "...";
      }
    },
    refreshRespondDealersPage() {
      this.getResponseList(this.selectedCreativesDealers.dealerID)
    },
    applySearchFilter(search, getDealersDataItem) {
      return getDealersDataItem.filter((soldier) =>
        soldier.dealerName.toLowerCase().startsWith(search.toLowerCase())
      )
    },
    applySearchResponseFilter(search, getDealersResponseDataItem) {
      return getDealersResponseDataItem.filter((soldier) =>
        soldier.dealerName.toLowerCase().startsWith(search.toLowerCase())
      )
    },
    getResponseList(id) {
      this.isResponseLoad = true
      this.responseMessageData = []
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL
          }/api/gmb/dealer/received_msg?dealerID=${id}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.responseMessageData = response.data.messageList;
          this.currentResponsePage = 1
          this.isResponseLoad = false
        })
        .catch((error) => {
          this.isResponseLoad = false
          console.log(error);
        });
    },
    openPreviewBasicInfo(item, status) {
      this.checkForApproval = status
      this.previewBasicDetails = []
      this.previewBasicDetails = item;
      this.previewDealerName = this.previewBasicDetails
      let workingHours = this.previewBasicDetails.workingHours;
      [this.previewStartTime, this.previewEndTime] = workingHours.split(" - ");
      this.previewNo = this.previewBasicDetails.mobileNo
      this.previewGoogleLink = this.previewBasicDetails.googleLink
      this.previewInstagramLink = this.previewBasicDetails.instagramLink
      this.previewAddress = this.previewBasicDetails.completeBusinessAddress
      this.previewOwener = this.previewBasicDetails.ownerName
      this.previewOwenerId = this.previewBasicDetails._id
      this.isConfirmInfoPreview = true
    },
    closePreviewBasicInfo() {
      this.previewBasicDetails = []
      this.isConfirmInfoPreview = false
    },
    refreshPage() {
      this.getDealersData()
      this.getNonResponsingList()
    },
    showPreviewNonRespondingDealers(data, type) {
      this.nonRespondingPreview = type
      this.nonRespondingCreatives = data.creativesList
      this.nonRespondingDealerName = ""
      this.nonRespondingOwnerName = ""
      this.nonRespondingNumber = ""
      this.workingHoursUnresponding = ""
      this.nonRespondingGoogle = ""
      this.nonRespondingAddress = ""
      this.nonRespondingInstagram = ""
      this.nonRespondingDealerName = data.dealerName
      this.nonRespondingNumber = data.mobileNo
      this.nonRespondingOwnerName = data.ownerName
      this.workingHoursUnresponding = data.workingHours
      this.nonRespondingGoogle = data.googleLink
      this.nonRespondingInstagram = data.instagramLink
      this.nonRespondingAddress = data.completeBusinessAddress
      this.previewOfNonResponfingFlag = true;
    },
    convertBackToOriginalFormat(inputDate) {
      const parsedDate = new Date(inputDate);
      const options = { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      const outputDate = parsedDate.toLocaleString('en-US', options);

      return outputDate;
    },
    getNonResponsingList() {
      this.isNonResponding = true
      this.nonRespondingDealersList = []
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL
          }/api/gmb/dealer/non-responding?clientID=${this.selectedSummary[0]}&hours=2`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.nonRespondingDealersList = response.data.unresponsiveDealersList
          this.isNonResponding = false
        })
        .catch((error) => {
          this.isNonResponding = false
          console.log(error);
        });
    },
    openRemindModal(item, typeRemind) {
      this.remindId = ''
      this.reminderType = ''
      this.remindTypeCheck = typeRemind
      this.remindsDealer = item
      if (item) {
        this.remindType = typeRemind
        this.remindId = item.dealerID
      }
      this.isOpenReminderModal = true
    },
    sendReminder() {
      this.isSendReminder = true
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/dealer/remind`,
          {
            dealerID: this.remindId,
            reminderType: this.remindType
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          if (this.remindType == 'basic info') {
            this.basicInfoConfirmation('REMINDER_SENT', this.remindsDealer)
          }
          if (this.remindType == 'creatives') {
            this.getDelearApproval('REMINDER_SENT', this.remindsDealer)
          }
          this.isSendReminder = false
          this.isOpenReminderModal = false
          this.showToast(
            "Success",
            'Reminder sent successfully!',
            "success"
          );
        })
        .catch((error) => {
          this.showToast("Error", "Unknown error occured.", "danger");
          this.isSendReminder = false
          this.isOpenReminderModal = false
          console.log(error)
        });
    },
    closeRemindModal() {
      this.isOpenReminderModal = false
    },
    handleCheckboxChange(index, id) {
      let isWeeklyChecked
      if (index == true) {
        isWeeklyChecked = "Success! Weekly reports has been enabled."
      } else {
        isWeeklyChecked = "Success! Weekly reports has been disabled."
      }
      this.weeklyReportEnabledFlag = index;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/dealer/enable-weekly-report`,
          {
            dealerID: id,
            isWeeklyReportEnabled: this.weeklyReportEnabledFlag
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.getDealersData();
          this.showToast(
            "Success",
            isWeeklyChecked,
            "success"
          );
        })
        .catch((error) => {
          console.log(error)
        });
    },
    formatMoney(number) {
      if (typeof number !== 'string') {
        return '';
      }

      const numString = String(number);
      const [integerPart, decimalPart] = numString.split('.');
      const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      const formattedNumber = decimalPart
        ? `${formattedIntegerPart}.${decimalPart}`
        : formattedIntegerPart;

      return formattedNumber;
    }
    ,
    redirectToUrl(url) {
      window.open(url, '_blank');
    },
    previewCreatives(item, name, no) {
      this.creativesPreview = item
      this.creativesDealerName = name
      this.creativesDealerNo = no
      this.isOpenApprovalDealersPreviewModal = true
    },
    formatDate(dateToFormat) {
      const dateParts = dateToFormat.split("-");
      const year = dateParts[0];
      const month = new Date(`${dateParts[1]}/01/2000`).toLocaleString('default', { month: 'short' });
      const day = parseInt(dateParts[2], 10);

      return `${day}-${month}'${year.substring(2)}`;
    },
    getAllSummaryTabelData() {
      this.getBrandSummaryTableData();
    },
    getAllSummaryChartData() {
      this.getBrandSummaryTableData();
    },
    isMonthSummaryDisabled(month) {
      if (this.selectedYear == '2024') {
        const currentMonth = new Date().getMonth();
        const monthIndex = this.dealersMonthSelections.indexOf(month);
        return monthIndex >= currentMonth + 1;
      }
    },
    sendCampaign() {
      this.isCampaignsSend = true
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/dealer/update/campaignDuration`,
          {
            dealerID: this.campaignDelaerID,
            startDate: new Date(this.dateRange.startDate).toISOString().split('T')[0],
            endDate: new Date(this.dateRange.endDate).toISOString().split('T')[0]
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.showToast(
            "Success",
            "Success! Campaign duration for the dealer has been added.",
            "success"
          );
          this.isCampaignsSend = false
          this.campaignDurationErrorText = ""
          this.isCloseCampaigns()
        })
        .catch((error) => {
          this.campaignDurationErrorText = error.response.data.error
          this.isCampaignsSend = false
        });
    },
    isOpenApprovalDealersModalDealers(item, id, name, number, images, owner) {
      this.updatedLists = item;
      this.ApprovalDelearName = name;
      this.dealerID = id;
      this.ApprovalDelearNumber = number;
      this.ApprovalImages = images;
      this.ownerCreativesName = owner;
      this.getCreativesData(this.dealerID)
      this.isOpenApprovalDealersModal = true;
    },
    updateValues(values) {
      let startDate = moment(values.startDate).format("YYYY-MM-DD");
      let endDate = moment(values.endDate).format("YYYY-MM-DD");
      this.startDateForAll = startDate;
      this.endDateForAll = endDate;
    },
    dateChanged(val) {
      this.$emit("fetchNewData", val.code);
    },
    dateFormat(classes, date) {
      let yesterday = new Date();

      let d1 = moment(date).format("DD-MM-YYYY");
      let d2 = moment(yesterday.setDate(yesterday.getDate() - 1)).format(
        "DD-MM-YYYY"
      );
      return classes;
    },
    formatDateDisplay(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    getCampaignsData(item) {
      this.campaignDurationErrorText = ""
      this.campaignDelaersData = item
      this.campaignDelaerName = this.campaignDelaersData.dealerName;
      this.campaignDelaerNumber = this.campaignDelaersData.mobileNo;
      this.campaignDelaerID = this.campaignDelaersData._id
      this.isOpenCampaignsModal = true;
    },
    isCloseCampaigns() {
      this.isOpenCampaignsModal = false;
    },

    openStatusModal(name, item, basic) {
      this.statusData = item;
      this.statusBasicData = basic;
      this.dealerWhatappsName = name;
      this.isOpenStatusUploadModal = true;
    },
    closeStatusModal() {
      this.isOpenStatusUploadModal = false;
    },
    removeFile(index) {
      this.imagePreviews.splice(index, 1);
    },
    removeApprovalFile(key) {
      this.creativesDataLists.splice(key, 1);
    },
    getBasicInfo(item) {
      this.basicInfo = []
      this.basicInfo = item;
      this.dealerAdress = this.basicInfo.completeBusinessAddress;
      this.dealerNum = this.basicInfo.mobileNo;
      this.ownerName = this.basicInfo.ownerName;
      this.instagramLink = this.basicInfo.instagramLink === "" ? " " : this.basicInfo.instagramLink
      this.googleMapsLink = this.basicInfo.googleLink === "" ? " " : this.basicInfo.googleLink
      this.workingHoursForBasic = this.selectedTime + " - " + this.selectedEndTime
      this.basicInfoDealerID = this.basicInfo._id;
      this.isConfirmInfo = true;
    },
    basicInfoConfirmation(conditionRenderingMessage = '', item = {}) {
      this.conditionaLdealerID = ""
      this.conditionaLowner = ""
      this.conditionaLcompeconditionaLBusinessAddress = ""
      this.conditionaLmobile_number = ""
      this.conditionaLworkingHour = ""
      this.conditionaLinstagram = ""
      this.conditionaLgoogle = ""
      this.conditionaLclientId = ""
      if (Object.keys(item).length > 0 && conditionRenderingMessage == 'REMINDER_SENT') {
        this.conditionaLcompeconditionaLBusinessAddress = item.completeBusinessAddress;
        this.conditionaLowner = item.ownerName;
        this.conditionaLmobile_number = item.mobileNo;
        this.conditionaLgoogle = item.googleLink;
        this.conditionaLinstagram = item.instagramLink;
        this.conditionaLdealerID = item.dealerID;
        this.conditionaLworkingHour = item.workingHours;
        this.conditionaLclientId = this.selectedSummary[0];
      }
      this.conditionRenderingReminderMessage = '';

      if (conditionRenderingMessage == 'REMINDER_SENT') {
        this.conditionRenderingReminderMessage = conditionRenderingMessage
      }
      this.isBasicInfoConfirm = true;

      if (conditionRenderingMessage == 'basicInfo' || conditionRenderingMessage == 'resend') {
        this.conditionRenderingReminderMessage = ''
      }
      if (conditionRenderingMessage == 'resend') {
        this.conditionaLdealerID = this.previewOwenerId;
        this.conditionaLclientId = this.selectedSummary[0];
        this.conditionaLowner = this.previewOwener;
        this.conditionaLcompeconditionaLBusinessAddress = this.previewAddress;
        this.conditionaLmobile_number = this.previewNo;
        this.conditionaLworkingHour =
          this.previewStartTime + " - " + this.previewEndTime;
        this.conditionaLinstagram = this.previewInstagramLink;
        this.conditionaLgoogle = this.previewGoogleLink;
      }
      if (conditionRenderingMessage == 'basicInfo') {
        this.conditionaLdealerID = this.basicInfoDealerID
        this.conditionaLclientId = this.selectedSummary[0];
        this.conditionaLowner = this.ownerName;
        this.conditionaLcompeconditionaLBusinessAddress = this.dealerAdress;
        this.conditionaLmobile_number = this.dealerNum;
        this.conditionaLworkingHour =
          this.selectedTime + " - " + this.selectedEndTime
        this.conditionaLinstagram = this.instagramLink
        this.conditionaLgoogle = this.googleMapsLink
      }
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/dealer/confirm/basic-info`,
          {
            dealerID: this.conditionaLdealerID,
            clientID: this.conditionaLclientId,
            ownerName: this.conditionaLowner,
            completeBusinessAddress: this.conditionaLcompeconditionaLBusinessAddress,
            mobileNo: this.conditionaLmobile_number,
            workingHours: this.conditionaLworkingHour,
            instagramLink: this.conditionaLinstagram,
            googleLink: this.conditionaLgoogle,
            status: this.conditionRenderingReminderMessage
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.conditionaLdealerID = ""
          this.previewOwener = " "
          this.conditionaLowner = ""
          this.previewNo = ''
          this.previewAddress = ""
          this.conditionaLcompeconditionaLBusinessAddress = ""
          this.conditionaLmobile_number = ""
          this.conditionaLworkingHour = ""
          this.conditionaLinstagram = ""
          this.conditionaLgoogle = ""
          this.conditionaLclientId = ""
          this.previewStartTime = "  "
          this.previewEndTime = " "
          this.previewOwenerId = ''
          this.previewInstagramLink = ''
          this.previewGoogleLink = ''
          this.showToast(
            "Success",
            "Dealer's Basic Info Sent for Approval!",
            "success"
          );
          this.isBasicInfoConfirm = false;
          this.isConfirmInfo = false;
          this.isConfirmInfoPreview = false
        })
        .catch((error) => {
          this.showToast("Error", "Unknown error occured.", "danger");
          this.isBasicInfoConfirm = false;
          this.isConfirmInfo = false;
          this.isConfirmInfoPreview = false
        });
    },
    updateCharacterCount() {
      this.characterCount = this.reviewDetailsComment.length;
      if (this.characterCount > 200) {
        this.reviewDetailsComment = this.reviewDetailsComment.slice(0, 200);
        this.characterCount = 200;
      }
    },

    closeIsConfirmModal() {
      this.isConfirmInfo = false;
    },
    creativesImageLimit() {
      this.isImagePreviewLimit = true;
    },
    creativesImageLimitClose() {
      this.isImagePreviewLimit = false;
    },
    getIndividualDealerData(id, name, code) {
      this.individualDealerName = name;
      this.individualDealerCode = code;
      this.isFetchedIndividualData = true;
      this.individualData = [];
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL
          }/api/gmb/dealer/individual-summary?clientID=${this.selectedSummary[0]
          }&dealerID=${id == undefined ? this.dealerIndivialID : id}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          let originalDealerData = response.data;
          for (const name in originalDealerData) {
            if (originalDealerData.hasOwnProperty(name)) {
              const item = originalDealerData[name];
              this.individualData.push({
                name,
                ...item,
              });
            }
          }
          this.isFetchedIndividualData = false;
        })
        .catch((error) => {
          this.isFetchedIndividualData = false;
          console.log(error);
        });
    },
    exportTableToCSV() {
      const csv = [];
      const rows = this.$el.querySelectorAll(".my-table tbody tr");

      for (let i = 0; i < rows.length; i++) {
        const row = [];
        const cols = rows[i].querySelectorAll("td");

        for (let j = 0; j < cols.length; j++) {
          row.push(cols[j].textContent.trim());
        }

        csv.push(row.join(","));
      }

      const header = Array.from(
        this.$el.querySelectorAll(".my-table thead th")
      ).map((th) => th.textContent.trim());
      csv.unshift(header.join(","));

      const blob = new Blob([csv.join("\n")], {
        type: "text/csv;charset=utf-8;",
      });

      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `${this.selectedSummary[1]}-Dealers.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    getPreviewImage(image) {
      this.previewImageDealer = image;
      this.isImagePreview = true;
    },
    getPreviewClose() {
      this.isImagePreview = false;
    },
    getDelearApproval(remindMessage, item = {}) {
      console.log(item)
      let metaData = []
      let dealerId = ''
      let ownerName = ''
      let mobile_number = ''
      let clientID = ''
      if (Object.keys(item).length > 0) {
        metaData = []
        dealerId = item.dealerID
        clientID = this.selectedSummary[0]
        mobile_number = item.mobileNo
        ownerName = item.ownerName
      }
      if (Object.keys(item).length === 0) {
        metaData = this.updatedLists
        dealerId = this.dealerID
        clientID = this.selectedSummary[0]
        mobile_number = this.ApprovalDelearNumber
        ownerName = this.ownerCreativesName
      }

      this.conditionRenderingReminderMessage = ''
      this.conditionRenderingReminderMessage = remindMessage || ''
      this.isGetApprovalSend = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/dealer/get-creatives-approval`,
          {
            metaData: metaData,
            dealerID: dealerId,
            clientID: clientID,
            mobileNumber: mobile_number,
            ownerName: ownerName,
            status: this.conditionRenderingReminderMessage
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.showToast("Success", "Details Sent for Approval!", "success");
          this.isGetApprovalSend = false;
          this.isOpenApprovalDealersModal = false;
        })
        .catch((error) => {
          this.showToast("Error", "Unknown error occured.", "danger");
          this.isGetApprovalSend = false;
          this.isOpenApprovalDealersModal = false;
          console.error(error);
        });
    },
    pageChanged(newPage) {
      this.currentPage = newPage;
    },
    getDealersData() {
      this.currentDealerPage = 1;
      this.currentUnRespondingPage = 1;
      this.isGetDealersData = true;
      let isCreativesTabOpen
      if (this.activeTab === "Creatives") {
        isCreativesTabOpen = false
      }
      if (this.activeTab === "Incoming") {
        isCreativesTabOpen = true
      }
      this.getDealersDataItem = [];
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/dealer/dealers?clientID=${this.selectedSummary[0]}&whatsappMessage=${isCreativesTabOpen}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.getDealersResponseDataItem = response.data.storesDynamicData;
          this.getDealersDataItem = response.data.storesDynamicData;

          if (this.activeTab === "Creatives" && this.selectedCreativesDealers == null) {
            this.selectedCreativesDealers = this.getDealersDataItem[0]
          }
          if (this.activeTab === "Incoming") {
            this.selectedResponseCreativesDealers = this.getDealersResponseDataItem[0]
          }

          this.individualDealerName = this.getDealersDataItem[0].dealerName;
          this.individualDealerCode = this.getDealersDataItem[0].dealerMisCode;
          this.dealerIndivialID = this.getDealersDataItem[0]._id;
          if (this.activeTab === "Dealers") {
            this.getIndividualDealerData(this.getDealersDataItem[0]._id, this.getDealersDataItem[0].dealerName, this.getDealersDataItem[0].dealerMisCode)
          }

          // this.getCreativesData(this.getDealersDataItem[0].dealerID);
          this.isGetDealersData = false;

        })
        .catch((error) => {
          this.isGetDealersData = false;
          console.log(error);
        });
    },
    getDeadersDataOnchange() {
      this.getDealersData();
      this.getCreativesData();
      this.getNonResponsingList();
    },
    getDeadersMonthsOnchange() {
      this.getDealersData();
      this.getCreativesData();
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    previewImage(event) {
      const newFiles = event.target.files;
      for (let i = 0; i < newFiles.length; i++) {
        this.selectedCreatives.push(newFiles[i]);
      }
      const filesInput = this.$refs.fileInput;
      if (filesInput && filesInput.files && filesInput.files.length > 0) {
        if (this.imagePreviews.length + newFiles.length > 20) {
          this.imagePreviews = [];
        }
        for (let i = 0; i < Math.min(20, filesInput.files.length); i++) {
          const file = filesInput.files[i];
          const reader = new FileReader();
          reader.onload = () => {
            this.imagePreviews.push(reader.result);
          };
          reader.readAsDataURL(file);
        }
        this.filesSelected = true;
      } else {
        this.imagePreviews = [];
        this.filesSelected = false;
      }
    },

    async uploadCreatives() {
      this.isCreativesUpload = true;
      const serverUrl = `${process.env.VUE_APP_SERVER_URL}/api/gmb/dealer/upload-creatives`;
      const authToken = `Bearer ${getUserToken()}`;
      const clientID = this.selectedSummary[0];
      const dealerID = this.selectedCreativesDealers.dealerID;
      try {
        const filesInput = this.$refs.fileInput;
        if (filesInput && filesInput.files && filesInput.files.length > 0) {
          const formData = new FormData();
          formData.append("clientID", clientID);
          formData.append("dealerID", dealerID);
          for (let i = 0; i < this.selectedCreatives.length; i++) {
            formData.append("files", this.selectedCreatives[i]);
          }
          const response = await axios.post(serverUrl, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: authToken,
            },
          });
          this.isCreativesUpload = false;
          this.showToast("Success", "Creative Upload Successful!", "success");
          this.$refs.fileInput.value = null;
          this.imagePreviews = [];
          this.selectedCreatives = [];
        } else {
          console.error("No files selected");
        }
        this.getCreativesData(this.selectedCreativesDealers.dealerID);
        this.isCloseCreativesUploadModal();
      } catch (error) {
        this.isCreativesUpload = false;
        this.showToast(
          "Error",
          "Oops! Something went wrong during the creative upload. Please try again or contact support for assistance.",
          "danger"
        );
        console.error(
          "Error:",
          error.response ? error.response.data : error.message
        );
        this.isCloseCreativesUploadModal();
      }
    },
    handleFileChange(event) {
      this.file = event.target.files[0];
    },
    updateCharacterCount() {
      this.characterCount = this.dealerAdress.length;
      if (this.characterCount > 300) {
        this.dealerAdress = this.dealerAdress.slice(0, 300);
        this.characterCount = 300;
      }
    },
    uploadFile() {
      if (this.file) {
        this.fileNames.push(this.file.name);
        this.$refs.fileInput.value = null;
      }
    },
    isUploadDealerModal() {
      this.isOpenUploadDealersModal = true;
    },
    handleButtonClick() {
      if (this.creativesDataLists.length === 20) {
        this.creativesImageLimit();
      } else {
        this.isOpenCreativesUploadModalItem();
      }
    },
    isOpenCreativesUploadModalItem() {
      this.isOpenCreativesUploadModal = true;
    },
    isCloseCreativesUploadModal() {
      this.imagePreviews = [];
      this.isOpenCreativesUploadModal = false;
    },
    isOpenAlloctedBudgetDealersModalDealers(id, name, mobileNo, budget) {
      this.dealerID = id;
      this.alocatedBudget = budget;
      this.dealersName = name;
      this.dealersMobileNumber = mobileNo;
      this.dealersBudget = budget;

      this.isOpenAlloctedBudgetDealersModal = true;
    },
    isOpenAlloctedBudgetDealersModalDealersClose() {
      this.isOpenAlloctedBudgetDealersModal = false;
    },
    isOpenUploadDealersModalClose() {
      this.isOpenUploadDealersModal = false;
    },
    closeApprovalModal() {
      this.isOpenApprovalDealersModal = false;
      this.creativesDataLists = [...this.originalCreativesDataLists];
    },
    closeApprovalPreviewModal() {
      this.isOpenApprovalDealersPreviewModal = false
    },

    setIntialNewOrganicClient() {
      if (this.selectedClientSummary == "") {
        this.selectedSummary = [
          this.fetchClientsList[0]._id,
          this.fetchClientsList[0].name,
        ];
        this.selectedClientSummary = this.fetchClientsList[0]._id;
        this.selectedCreativesId = this.fetchClientsList[0]._id;
        this.selectedDealersId = this.fetchClientsList[0]._id;
        this.selectedCreativesUploadId = this.fetchClientsList[0]._id;
        this.getSummaryNewData();
      }
    },
    getSummaryNewData(startDate, endDate) {
      this.getBrandSummaryTableData(startDate, endDate);
    },
    getCreativesDataOnchange() {
      this.getCreativesData();
      this.selectedCreativesDealers = null
      this.getDealersData()
    },
    getResponseData() {
      this.getDealersData()
      this.selectedCreativesDealers = null
      this.getResponseList()
    },
    getCreativesData(dealerID) {
      this.isCreativesLoadProperly = true;
      this.creativesDataLists = [];
      this.originalCreativesDataLists = []
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/dealer/creatives?clientID=${this.selectedSummary[0]}&dealerID=${dealerID}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          let creatives = response.data.creatives
          if (creatives && typeof creatives[Symbol.iterator] === 'function') {
            this.originalCreativesDataLists = [...creatives];
            this.creativesDataLists = [...creatives];
            this.isCreativesLoadProperly = false;
          } else {
            console.error('Invalid or undefined data:', creatives);
          }
        })
        .catch((error) => {
          creatives = []
          console.log(error);
          this.isCreativesLoadProperly = false;
        });
    },
    showCancelButton() {
      this.isHovered = true;
    },
    hideCancelButton() {
      this.isHovered = false;
    },
    async deleteCreativesPost(imgUrl, id) {
      this.deleteCreativesUrl = imgUrl;
      this.deleteCreativesId = id;
      this.showConfirmationDeleteCreativesModal = true;
    },
    async deleteCreativesPostAfterConfirmation() {
      this.disabledDeleteButton = true;
      this.isCreativesDelete = true;
      const url = `${process.env.VUE_APP_SERVER_URL}/api/gmb/dealer/creatives?id=${this.deleteCreativesId}`;
      const clientID = this.selectedCreativesId;
      try {
        const response = await axios.delete(url, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${getUserToken()}`,
          },
          data: `clientID=${clientID}&files=${this.deleteCreativesUrl}`,
        });
        this.getCreativesData(this.selectedCreativesDealers.dealerID);
        this.showToast(
          "Success",
          "The selected creative has been successfully deleted. Keep your campaign content fresh!",
          "success"
        );
        this.isCreativesDelete = false;
        this.showConfirmationDeleteCreativesModal = false;
        this.disabledDeleteButton = false;
      } catch (error) {
        this.isCreativesDelete = false;
        this.disabledDeleteButton = false;
        this.showConfirmationDeleteCreativesModal = false;
        this.showToast(
          "Error",
          "Unable to Delete Creative! Please try again or contact support.",
          "danger"
        );
        console.error(error.response.data);
      }
    },
    uploadDealersCSV() {
      this.isUploadCSV = true;
      const formData = new FormData();
      formData.append("csvFile", this.$refs.csvFile.files[0]);
      formData.append("clientID", this.selectedSummary[0]);
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/dealer/csv/upload-dealers`,
          formData
        )
        .then((response) => {
          this.showToast("Success", "CSV Upload Successfully", "success");
          this.isUploadCSV = false;
          this.isOpenUploadDealersModalClose();
        })
        .catch((error) => {
          this.showToast("Error", "Unknown error occured.", "danger");
          this.isUploadCSV = false;
          console.error(error);
        });
    },
    updateDealersData() {
      this.isUpdateDealersData = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/dealer/update/budget`,
          {
            clientID: this.selectedSummary[0],
            dealerName: this.dealersName,
            mobileNo: this.dealersMobileNumber,
            dealerID: this.dealerID,
            budget: this.dealersBudget,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          if (this.alocatedBudget > 0) {
            this.showToast("Success", "Budget Update Successful!", "success");
          }
          if (this.alocatedBudget == 0) {
            this.showToast(
              "Success",
              "Budget Allocation Successful!",
              "success"
            );
          }
          this.getDealersData();
          this.isUpdateDealersData = false;
          this.isOpenAlloctedBudgetDealersModalDealersClose();
        })
        .catch((error) => {
          this.showToast("Error", "Unknown error occured.", "danger");
          this.isUpdateDealersData = false;
          console.error(error);
        });
    },
    fetchClients() {
      this.isLoadClientListForGMBDealers = true;
      this.newSummaryPageLoad = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/get`,
          {
            limit: 50,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.fetchClientsList = response.data.clients.filter((client) => {
            return client.gmb && client.gmb.accountID.length;
          });
          this.setIntialNewOrganicClient();
          this.isLoadClientListForGMBDealers = false;
          this.newSummaryPageLoad = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoadClientListForGMBDealers = false;
          this.newSummaryPageLoad = false;
        });
    },
    getBrandSummaryTableData(
      startDate = null,
      endDate = null) {
      // if (!dateRange) {
      //   dateRange = "LAST_7_DAYS";
      // }
      // this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!startDate && !endDate) {
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];

        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      }
      if (startDate && endDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${startDate}&endDate=${endDate}`;
      }
      this.isSummaryPageLoad = true;
      this.summaryNewData = [];
      this.getSummaryChartData = [];
      // this.costPerIncrementalStoreVisitCount = 0;
      // this.costPerIncrementalStoreVisitCountFormatted = 0;
      // this.costPerIncrementalStoreVisitCountPrevMonth = 0;
      // this.costPerIncrementalStoreVisitCountPrevMonthFormatted = 0;
      // this.costPerIncrementalStoreVisitDeltaPrevMonth = 0;
      // this.costPerStoreVisitCount = 0;
      // this.costPerStoreVisitCountFormatted = 0;
      // this.costPerStoreVisitCountPrevMonth = 0;
      // this.costPerStoreVisitCountPrevMonthFormatted = 0;
      // this.costPerStoreVisitDeltaPrevMonth = 0;
      // this.incrementalStoreVisitsColor = 0;
      // this.incrementalStoreVisitsCount = 0;
      // this.incrementalStoreVisitsCountPrevMonth = 0;
      // this.incrementalStoreVisitsDeltaPrevMonth = 0;
      // this.storeVisitsCount = 0;
      // this.storeVisitsCountPrevMonth = 0;
      // this.storeVisitsDeltaPrevMonth = 0;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/dealer/brand-summary?clientID=${this.selectedSummary[0]}${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          // this.getSummaryChartData = response.data.metrics;
          // if (this.getSummaryChartData != {}) {
          //   this.prevMonth = this.getSummaryChartData.prevMonth;
          //   this.prePrevMonth = this.getSummaryChartData.prePrevMonth;
          //   this.costPerIncrementalStoreVisitColor = this.getSummaryChartData.costPerIncrementalStoreVisit.color;
          //   this.costPerIncrementalStoreVisitCount = this.getSummaryChartData.costPerIncrementalStoreVisit.count;
          //   this.costPerIncrementalStoreVisitCountFormatted = this.getSummaryChartData.costPerIncrementalStoreVisit.countFormatted;
          //   this.costPerIncrementalStoreVisitCountPrevMonth = this.getSummaryChartData.costPerIncrementalStoreVisit.countPrevMonth;
          //   this.costPerIncrementalStoreVisitCountPrevMonthFormatted = this.getSummaryChartData.costPerIncrementalStoreVisit.countPrevMonthFormatted;
          //   this.costPerIncrementalStoreVisitDeltaPrevMonth = this.getSummaryChartData.costPerIncrementalStoreVisit.deltaPrevMonth;
          //   this.costPerStoreVisitColor = this.getSummaryChartData.costPerStoreVisit.color;
          //   this.costPerStoreVisitCount = this.getSummaryChartData.costPerStoreVisit.count;
          //   this.costPerStoreVisitCountFormatted = this.getSummaryChartData.costPerStoreVisit.countFormatted;
          //   this.costPerStoreVisitCountPrevMonth = this.getSummaryChartData.costPerStoreVisit.countPrevMonth;
          //   this.costPerStoreVisitCountPrevMonthFormatted = this.getSummaryChartData.costPerStoreVisit.countPrevMonthFormatted;
          //   this.costPerStoreVisitDeltaPrevMonth = this.getSummaryChartData.costPerStoreVisit.deltaPrevMonth;
          //   this.incrementalStoreVisitsColor = this.getSummaryChartData.incrementalStoreVisits.color;
          //   this.incrementalStoreVisitsCount = this.getSummaryChartData.incrementalStoreVisits.count;
          //   this.incrementalStoreVisitsCountPrevMonth = this.getSummaryChartData.incrementalStoreVisits.countPrevMonth;
          //   this.incrementalStoreVisitsDeltaPrevMonth = this.getSummaryChartData.incrementalStoreVisits.deltaPrevMonth;
          //   this.storeVisitsColor = this.getSummaryChartData.storeVisits.color;
          //   this.storeVisitsCount = this.getSummaryChartData.storeVisits.count;
          //   this.storeVisitsCountPrevMonth = this.getSummaryChartData.storeVisits.countPrevMonth;
          //   this.storeVisitsDeltaPrevMonth = this.getSummaryChartData.storeVisits.deltaPrevMonth;
          // }
          let originalSummaryData = response.data.dealers;
          for (const name in originalSummaryData) {
            if (originalSummaryData.hasOwnProperty(name)) {
              const item = originalSummaryData[name];
              this.summaryNewData.push({
                name,
                ...item,
              });
            }
          }
          this.updateSummayNewChartData();
          this.newSummaryPageLoad = false;
          this.isSummaryPageLoad = false;
        })
        .catch((error) => {
          this.seriesSummaryNewCPI = [
            {
              name: "Paid SV",
              data: [this.storeVisitsCountPrevMonth, this.storeVisitsCount],
            },
          ]
          this.seriesSummaryNewCPIIncremental = [
            {
              name: "Incremental SV",
              data: [0, 0],
            },
          ],
            this.seriesSummaryNewCPSV = [
              {
                name: "CPSV",
                data: [0, 0],
              },
            ],
            this.seriesSummaryNewCPICPISV = [
              {
                name: "CPISV",
                data: [0, 0],
              },
            ],
            console.log(error);
          this.newSummaryPageLoad = false;
          this.isSummaryPageLoad = false;
        });
    },
    getDealerSummaryTableData() {
      this.summaryNewDealerData = [];

      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/dealer-summary?storeID=${this.selectedSummary[0]}'`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          let originalDealerData = response.data;
          for (const name in originalDealerData) {
            if (originalDealerData.hasOwnProperty(name)) {
              const item = originalDealerData[name];
              this.summaryNewDealerData.push({
                name,
                ...item,
              });
            }
          }
        })
        .catch((error) => {
          this.seriesSummaryNewCPI = [
            {
              name: "Paid SV",
              data: [this.storeVisitsCountPrevMonth, this.storeVisitsCount],
            },
          ]
          this.seriesSummaryNewCPIIncremental = [
            {
              name: "Incremental SV",
              data: [0, 0],
            },
          ],
            this.seriesSummaryNewCPSV = [
              {
                name: "CPSV",
                data: [0, 0],
              },
            ],
            this.seriesSummaryNewCPICPISV = [
              {
                name: "CPISV",
                data: [0, 0],
              },
            ],
            console.log(error);
          this.newSummaryPageLoad = false;
          console.log(error);
        });
    },
    updateSummayNewChartData() {
      (this.seriesSummaryNewCPI = [
        {
          name: "Paid SV",
          data: [this.storeVisitsCountPrevMonth, this.storeVisitsCount],
        },
      ]),
        (this.seriesSummaryNewCPIIncremental = [
          {
            name: "Incremental SV",
            data: [
              this.costPerIncrementalStoreVisitCountPrevMonth,
              this.costPerIncrementalStoreVisitCount,
            ],
          },
        ]),
        (this.seriesSummaryNewCPSV = [
          {
            name: "CPSV",
            data: [
              this.costPerStoreVisitCountPrevMonth,
              this.costPerStoreVisitCount,
            ],
          },
        ]),
        (this.seriesSummaryNewCPICPISV = [
          {
            name: "CPISV",
            data: [
              this.incrementalStoreVisitsCountPrevMonth,
              this.incrementalStoreVisitsCount,
            ],
          },
        ]);
      this.optionsSummaryNew = {
        chart: {
          // height: 350,
          // type: "line",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          align: "left",
        },
        grid: {
          row: {
            colors: ["transparent", "transparent"],
            opacity: 0.5,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: [this.prePrevMonth, this.prevMonth],
        },
      };
    },
    changeTab(tabName) {
      this.activeTab = tabName;
      this.selectedCreativesDealers = null
      if (this.activeTab === "Summary_new") {
        this.getSummaryNewData();
      }
      if (this.activeTab === "Creatives") {
        this.getDealersData();
      }
      if (this.activeTab === "Dealers") {
        this.getDealersData();
        this.getCreativesData();
        this.getNonResponsingList();
      }
      if (this.activeTab === "Incoming") {
        this.getDealersData();
      }
    },
    selectAllRows() {
      if (this.selectAll) {
        this.selectedItems = this.items.map((item) => item.id);
      } else {
        this.selectedItems = [];
      }
    },
  },
  mounted() {
    this.fetchClients();
    this.activeTab = "Summary_new";
  },
};
</script>

<style lang="scss">
.summary-filters {
  display: flex;
  justify-content: space-between
}

.manage-overflow{
   overflow-x: auto;
}
.budget-column {
  width: 100px;
}

.respondTest {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.error-text {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.picker-controller {
  background-color: #f5f6fa;
  padding: 8px;
}

.filterCardSelect {
  width: 225px;
  margin-left: -5px;
  margin-right: auto;
}

.time-selector {
  .time-select-container {
    max-width: 300px;
    margin: 20px auto;
    background-color: white !important;
  }

  .label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }

  .select {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    color: #575757;
    border-radius: 4px;
    background-color: white !important;
    font-size: 13px;
  }

  .selected-time {
    margin-top: 10px;
    font-style: italic;
    color: #333;
  }
}

.gmb-dashboard-overlay .rounded-sm {
  inset: -10px !important;
  height: auto !important;
}

.misCode {
  width: 140px;
}

.gmb-dashboard-overlay-dealers .rounded-sm {
  inset: -15px !important;
  height: auto !important;
}

.dashboard-overlay .rounded-sm {
  inset: -15px !important;
  height: auto !important;
}

.dashboard-overlay-individual .rounded-sm {
  inset: -10px !important;
  height: auto !important;
}

.card-container-graph {
  width: 200px;
}

.chartSummaryContainer {
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.summaryHeaderContainer {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #f6edfe;
  width: 101.2%;
  margin-left: -8px;
  border-radius: 5px;
}

.creativesManagerContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 40px;
}

.cardContainerForSubHeader {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  gap: 1rem;
}

.headerManagerContainer {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #f6edfe;
}

.creativesSubHeader {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: -10px;
}

.creativesSubHeaderSummary {
  display: flex;
  gap: 0.5rem;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 10px;
}

.creativesListImageContainer img.creativesListImage {
  width: 100%;
  height: auto;
  max-width: 100%;
  display: block;
}

.image-grid-creatives {
  display: flex;
  padding: 10px;
  align-items: center;
}

.image-grid-creatives-approval {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 2px;
}

.image-grid-unrespond-creatives-approval {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 2px;
  margin-left: 8px;
  margin-bottom: 10px;
}

.file-input-container {
  margin-right: 10px;
}

.image-previews {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.image-previews-approval {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.image-preview-creatives {
  flex-shrink: 0;
  width: 120px;
  height: auto;
  margin-right: 10px;
  margin-top: 10px;
}

.image-preview {
  max-width: 120px;
  height: auto;
}

.image-preview-creatives {
  width: 90px;
  height: 90px;
  border: 1px solid black;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
  background-size: cover;
}

.my-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.my-table th {
  border-bottom: 1px solid #000000;
  border-top: 1px solid #000000;
  padding: 8px;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-weight: bold;
}

.my-table td {
  padding: 3px;
  justify-content: center;
  text-align: center;
  align-items: center;
}

input[type="checkbox"] {
  margin-right: 5px;
  cursor: pointer;
}

.tabs-container .card-body {
  padding: 1rem !important;
}

.modal-container {
  position: relative;
}

.close-button {
  position: absolute;
  top: -35px;
  right: -35px;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.centered-image {
  max-width: 110%;
  max-height: 110%;
  margin-left: -5%;
  margin-top: -5%;
  margin-bottom: -5%;
  object-fit: cover;
}

.gmb-client-select {
  border-right: 10px solid transparent;
  outline: 1px solid #d8d6de;
  width: 100% !important;
  height: 38px;
  border: 0px;
  padding-left: 10px;
  background-color: white;
  border-radius: 5px;
  color: rgb(163, 153, 153);
}

.budgetAllocationInput {
  padding: 8px;
  border: 1px solid #d8d6de !important;
  border-radius: 5px !important;
}

.budgetAllocationInputName {
  width: 222px;
}

.alignment-container {
  display: flex;
  justify-content: space-around;
  gap: 1rem;
  margin-top: 10px;
}

.equal-size {
  flex: 1;
}

.small-label {
  font-size: 10px;
  color: #a8a7a7;
}

.dropdown-style {
  width: 100%;
  height: 37px;
  background-color: #fafafa !important;
  cursor: not-allowed !important;
  margin-bottom: 5px;
}

.image-container {
  border: 1px dashed rgb(217, 214, 214);
  border-radius: 5px;
  height: auto;
  width: 100%;
}

.error-message {
  color: red;
}

.cardUploadCreatives {
  border: 1px solid #dddddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 2px;
  width: 90px;
  height: 90px;
  padding: 5px;
}

.file-input-container {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.file-input {
  font-size: 100px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
}

.icon {
  margin-right: 5px;
}

.file-input:not(:empty)+.custom-button .icon {
  display: inline;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.file-input-container {
  grid-column: span 3;
  display: flex;
}

.file-input {
  font-size: 100px;
  opacity: 0;
}

.cardUpload {
  border: 1px solid #dddddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 2px;
  max-width: 75px;
}

.custom-button {
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer !important;
}

.icon {
  margin-right: 5px;
}

.file-input:not(:empty)+.custom-button .icon {
  display: inline;
}

.image-preview {
  max-width: 100px;
}

.image-container-list {
  position: relative;
  display: inline-block;
}

.cancel-button {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  border: none;
  background: black;
  padding: 5px 10px;
  cursor: pointer;
  display: none;
}

.image-container-list:hover .cancel-button {
  display: block;
}

@media only screen and (max-width: 767px) {
  .budget-column {
    width: 100%;
  }

  .respondTest {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .creativesManagerContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding: 20px;
    justify-content: center;
    align-item: center;
  }

  .misCode {
    width: 100%;
  }

  .summary-filters {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .headerManagerContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding: 10px 20px;
    background-color: #f6edfe;
  }

  .cardContainerForSubHeader {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .creativesSubHeader {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .creativesListImageContainer {
    width: 100%;
    height: auto;
    max-width: 100%;
    display: block;
  }

  .creativesListImage {
    width: 100%;
    height: auto;
    max-width: 100%;
    display: block;
    border: 1px solid black;
  }


  .summaryHeaderContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding: 10px 20px;
    background-color: #f6edfe;
    width: 101.2%;
    margin-left: -8px;
    border-radius: 5px;
    margin: 10px;
  }

  .graph-summary-dealers {
    display: grid;
    grid-template-columns: auto;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .summaryContainer {
    margin-top: 200px;
  }

  .graph-card-summary,
  .card-container-graph {
    width: 1500px;
    height: auto;
    margin-left: 900px;
    padding: 110px;
    margin-bottom: -220px !important;
  }

  .my-table {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .my-table thead,
  .my-table tbody,
  .my-table tr {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .my-table th,
  .my-table td {
    width: 100%;
    box-sizing: border-box;
    text-align: left;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .cardContainerForSubHeader {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .respondTest {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .budget-column {
    width: 100%;
  }

  .summary-filters {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .misCode {
    width: 100%;
  }

  .creativesListImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 1px solid black;
  }

  .creativesListImageContainer {
    width: 100%;
    height: auto;
    max-width: 100%;
    display: block;
    overflow: hidden;
  }

  .creativesManagerContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    padding: 40px;
    justify-content: center;
  }
}

@media (min-width: 1024px) and (max-width: 1500px) {
  .card-summary {
    margin-top: -1.5% !important;
    margin-bottom: -1.5% !important;
    gap: 1.5rem !important;
  }

  .budget-column {
    width: 100%;
  }

  .summary-filters {
    display: flex;
    justify-content: space-between;
    gap: 0.3rem;
  }

  .graph-summary-dealers {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    justify-content: center;
    margin-left: -2.2% !important;
    align-items: center;
    gap: 1.5rem !important;

    .card-body {
      padding: 0.2rem !important;
    }
  }

  .graph-card-summary {
    min-width: 110%;
    height: auto;
    padding: 5px;
    margin: 10px;
  }

  .card-container-graph {
    min-width: 110%;
    height: auto;
    padding: 5px;
    margin: 10px;
  }

  .chart-text {
    margin-top: 10px !important;
  }

  .cardContainerForSubHeader {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .creativesListImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 1px solid black;
  }

  .creativesListImageContainer {
    width: 100%;
    height: auto;
    max-width: 100%;
    display: block;
    overflow: hidden;
  }


  .creativesManagerContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr) !important;
    padding: 40px;
    justify-content: center;
  }
}
</style>
